import type {
  CustomFieldEntityType,
  CustomFieldMappingHydraCollectionItem,
  CustomFieldMappingViewContextEnum,
} from '../custom_field_mappings/custom_field_mappings.interface.ts'
import type { CustomFieldValueHydraItem } from './custom_field_values.interface'
import type { CustomFieldValueValueSchemaValidate } from './custom_field_values.schema'
import { createInjectionState } from '@vueuse/shared'
import dayjs from 'dayjs'
import { getAll } from '../../api'
import { useServerErrorHandler } from '../../utils'
import { useCustomFieldMappingsStore } from '../custom_field_mappings'
import { CustomFieldMappingValueType } from '../custom_field_mappings/custom_field_mappings.interface'
import { PermissionAttributes, usePermissions } from '../permissions'
import { CustomFieldValueValueType, fieldTypeToValueType } from './custom_field_values.interface'

const [useProvideCustomFieldValues, useCustomFieldValuesRaw] = createInjectionState(
  ({
    iri: entityIri,
    customFieldEntityType,
    viewContext,
  }: {
    iri?: string | null
    customFieldEntityType: CustomFieldEntityType | ComputedRef<CustomFieldEntityType> | Ref<CustomFieldEntityType>
    viewContext?: (CustomFieldMappingViewContextEnum[] | null) | ComputedRef<(CustomFieldMappingViewContextEnum[] | null)> | Ref<(CustomFieldMappingViewContextEnum[] | null)>
  }) => {
    const { getMappingPerEntityType, customFieldMappingsList } = useCustomFieldMappingsStore()
    // state and composable logic will go here
    const loading = ref(false)

    const items = ref<CustomFieldValueHydraItem[]>([])

    const getCustomFieldMapping = (data: {
      customFieldEntityType: CustomFieldEntityType
      viewContext?: CustomFieldMappingViewContextEnum[] | null
    }) => {
      const mapping = getMappingPerEntityType(data.customFieldEntityType)

      if (data.viewContext) {
        const viewContextValue = toValue(data.viewContext)
        if (viewContextValue) {
          return mapping.filter(mapping => viewContextValue.some(context => mapping.viewContext.includes(context)))
        }
      }

      return mapping
    }

    const customFieldMapping = computed<CustomFieldMappingHydraCollectionItem[]>(() => {
      return getCustomFieldMapping({
        customFieldEntityType: toValue(customFieldEntityType),
        viewContext: toValue(viewContext),
      })
    })

    const getCustomFieldMapByIri = (iri: string) => {
      return customFieldMappingsList.value.find(mapping => mapping['@id'] === iri)
    }

    const getCustomFieldValueByMapping = (mapping: CustomFieldMappingHydraCollectionItem) => {
      return items.value.find(item => item.mapping === mapping['@id'])
    }

    const getCustomFieldValues = (
      inputData: {
        customFieldEntityType: CustomFieldEntityType
        viewContext?: CustomFieldMappingViewContextEnum[] | null
      } | undefined = undefined,
    ): CustomFieldValueValueSchemaValidate[] => {
      const mapping = inputData ? getCustomFieldMapping(inputData) : customFieldMapping.value
      return mapping.map((mapping) => {
        const customFieldValue = getCustomFieldValueByMapping(mapping)
        if (!customFieldValue) {
          const type = fieldTypeToValueType(mapping.valueType)
          return {
            '@id': '',
            'type': type,
            'mapping': mapping['@id'],
            'value': null,
            'allowedValues': type === CustomFieldValueValueType.DROPDOWN ? mapping.metadata?.allowedDropdownValues ?? null : null,
          }
        }

        let value: any = customFieldValue.value.value
        console.log('mapping', mapping)
        if (mapping.valueType === CustomFieldMappingValueType.DATE && value) {
          value = dayjs(value).toDate()
        }
        const type = fieldTypeToValueType(mapping.valueType)
        return {
          '@id': customFieldValue['@id'],
          'type': type,
          'mapping': mapping['@id'],
          'value': value,
          'allowedValues': type === CustomFieldValueValueType.DROPDOWN ? mapping.metadata?.allowedDropdownValues ?? null : null,
        }
      })
    }

    const { hasPermission } = usePermissions()

    const allowReadCustomFieldValues = computed(() =>
      hasPermission(
        PermissionAttributes.GLOBAL.OPERATION.CUSTOM_FIELD_VALUE.READ_COLLECTION_VIA_ENTITY,
      ),
    )

    const allowCreateCustomFieldValues = computed(() =>
      hasPermission(
        PermissionAttributes.GLOBAL.OPERATION.CUSTOM_FIELD_VALUE.CREATE,
      ),
    )

    const allowUpdateCustomFieldValues = computed(() =>
      hasPermission(
        PermissionAttributes.GLOBAL.OPERATION.CUSTOM_FIELD_VALUE.UPDATE,
      ),
    )

    const allowDeleteCustomFieldValues = computed(() =>
      hasPermission(
        PermissionAttributes.GLOBAL.OPERATION.CUSTOM_FIELD_VALUE.DELETE,
      ),
    )

    const { onError } = useServerErrorHandler()

    async function setCustomFieldValues(shouldLoad: boolean = true, iriOverride?: string): Promise<void> {
      try {
        const entityIriPath = iriOverride ?? entityIri
        if (!allowReadCustomFieldValues.value || !entityIriPath) {
          console.log('no allowReadCustomFieldValues.value or entityIri', entityIriPath)
          items.value = []
          return
        }
        if (shouldLoad) {
          loading.value = true
        }
        const url = `${entityIriPath}/custom_field_values`
        console.log('url', url, iriOverride)
        const data = await getAll<CustomFieldValueHydraItem>({
          url,
        })
        items.value = data
      }
      catch (error) {
        onError(error)
        throw error
      }
      finally {
        if (shouldLoad) {
          loading.value = false
        }
      }
    }

    const reset = () => {
      items.value = []
    }

    return {
      getCustomFieldValueByMapping,
      customFieldMapping,
      items,
      getCustomFieldMapByIri,
      reset,
      loading,
      setCustomFieldValues,
      allowCreateCustomFieldValues,
      getCustomFieldValues,
      allowUpdateCustomFieldValues,
      getMappingPerEntityType,
      allowDeleteCustomFieldValues,
    }
  },
)

export { useProvideCustomFieldValues }

// If you want to use the composable in a component, use this function
// which includes error handling if the provider is not found
export function useCustomFieldValues() {
  const customFieldValuesStore = useCustomFieldValuesRaw()
  if (customFieldValuesStore == null) {
    throw new Error(
      'Please call `useProvideCustomFieldValues` on the appropriate parent component',
    )
  }
  return customFieldValuesStore
}
