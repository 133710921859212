import { CaseFileSortEnum } from '../case_files'

export interface BrandStat {
  open: number
  closed: number
  amount: number

}

export interface BrandStats {
  Debtt: BrandStat
  DebttLegal: BrandStat
  DebttManagement: BrandStat
  Collect4u: BrandStat
}

export enum AdministrationIdEnum {
  Debtt = 1,
  DebttManagement = 2,
  Collect4u = 3,
}

export const AdministrationIdBrandMapEnum: Record<string, keyof BrandStats> = {
  [`${AdministrationIdEnum.Debtt}-${CaseFileSortEnum.CREDIT_MANAGEMENT}`]: 'Debtt',
  [`${AdministrationIdEnum.Debtt}-${CaseFileSortEnum.COLLECTION}`]: 'Debtt',
  [`${AdministrationIdEnum.Debtt}-${CaseFileSortEnum.LEGAL}`]: 'DebttLegal',
  [`${AdministrationIdEnum.DebttManagement}-${CaseFileSortEnum.CREDIT_MANAGEMENT}`]: 'DebttManagement',
  [`${AdministrationIdEnum.DebttManagement}-${CaseFileSortEnum.COLLECTION}`]: 'DebttManagement',
  [`${AdministrationIdEnum.DebttManagement}-${CaseFileSortEnum.LEGAL}`]: 'DebttManagement',
  [`${AdministrationIdEnum.Collect4u}-${CaseFileSortEnum.CREDIT_MANAGEMENT}`]: 'Collect4u',
  [`${AdministrationIdEnum.Collect4u}-${CaseFileSortEnum.COLLECTION}`]: 'Collect4u',
  [`${AdministrationIdEnum.Collect4u}-${CaseFileSortEnum.LEGAL}`]: 'Collect4u',
}
