import type { Component } from 'vue'
import type { ObjectSchema } from 'yup'
import { createTaskActionSchema, sendEmailActionSchema } from '../actions'
import { AutomationEntities } from '../entities/entities.ts'

export enum AutomationActionDocuments {
  SEND_MAIL_ACTION_DOCUMENT = 'SendMailActionDocument',
  CREATE_TASK_ACTION_DOCUMENT = 'CreateTaskActionDocument',
  // Add other action types here
}

export function automationActionOptions(t: (v: string) => string): {
  label: string
  value: AutomationActionDocuments
}[] {
  return Object.entries(AutomationActionDocuments).map(([key, value]) => ({
    label: t(`automations.action.options.${value}`),
    value: AutomationActionDocuments[key as keyof typeof AutomationActionDocuments],
  }))
}

export interface AutomationBaseAction {
  '#context': AutomationActionDocuments
}

export function AutomationActionSchemaMapping(t: (val: string) => string): Record<AutomationActionDocuments, ObjectSchema<any>> {
  return {
    [AutomationActionDocuments.SEND_MAIL_ACTION_DOCUMENT]: sendEmailActionSchema(t),
    [AutomationActionDocuments.CREATE_TASK_ACTION_DOCUMENT]: createTaskActionSchema(t),
  }
}

export const AutomationActionComponentMapping: Record<AutomationActionDocuments, Component> = {
  [AutomationActionDocuments.SEND_MAIL_ACTION_DOCUMENT]: defineAsyncComponent(() => import('../../../../components/Features/automations/components/Actions/ActionSendEmail.vue')),
  [AutomationActionDocuments.CREATE_TASK_ACTION_DOCUMENT]: defineAsyncComponent(() => import('../../../../components/Features/automations/components/Actions/ActionCreateTask.vue')),
}

export const AutomationEntityActions: Record<
  AutomationEntities,
  AutomationActionDocuments[]
> = {
  [AutomationEntities.Payment]: [AutomationActionDocuments.SEND_MAIL_ACTION_DOCUMENT, AutomationActionDocuments.CREATE_TASK_ACTION_DOCUMENT],
  [AutomationEntities.CaseFile]: [AutomationActionDocuments.SEND_MAIL_ACTION_DOCUMENT, AutomationActionDocuments.CREATE_TASK_ACTION_DOCUMENT],
}

export function automationEntityActionsOptions(
  t: (v: string) => string,
  entity?: AutomationEntities,
): {
    label: string
    value: AutomationActionDocuments
  }[] {
  if (!entity) {
    return []
  }
  return AutomationEntityActions[entity].map(value => ({
    label: t(`automations.actions.options.${value}.label`),
    value: value as AutomationActionDocuments,
  }))
}
