import type { InferType } from 'yup'
import type { DurationPatternDateEnum, HydraContext, HydraTimeBased } from '../../../common'
import type { GeneralLedgerHydraItem } from '../general_ledgers'
import type { InterestRateGroupsHydraItem } from '../interest_rate_groups'
import { mixed, number, object, string } from 'yup'
import { DurationPatternDateTimeEnum, VatTypeEnum } from '../../../common'

export enum InterestCalculationModeEnum {
  SIMPLE = 1,
  COMPOUNDED = 2,
}

export function interestRateSettingsObjectSchema() {
  return object({
    group: string().nullable(),
    refreshInterval: mixed()
      .oneOf(Object.values(DurationPatternDateTimeEnum))
      .required(),
    calculationMode: mixed()
      .oneOf(Object.values(InterestCalculationModeEnum))
      .required(),
    vatPercentage: number().default(21),
    vatType: mixed()
      .oneOf(Object.values(VatTypeEnum))
      .required()
      .default(VatTypeEnum.GENERIC),
    generalLedger: string().required(),
  })
}

export type InterestRateSettingsObjectSchema = InferType<
  ReturnType<typeof interestRateSettingsObjectSchema>
>

export interface InterestRateSettingsHydraItem
  extends HydraContext,
  HydraTimeBased {
  '@type': 'InterestRateSettings'
  'administration': string
  'group'?: InterestRateGroupsHydraItem | null
  'calculationMode': InterestCalculationModeEnum
  'refreshInterval': DurationPatternDateEnum
  'generalLedger': GeneralLedgerHydraItem
  'vatPercentage': number
  'vatType': VatTypeEnum
  'client'?: string // iri reference to Client
  'global'?: boolean
}

export interface InterestRateSettingWrite {
  group: string | null // iri reference to CollectionCostGroup
  vatPercentage: number
  vatType: VatTypeEnum
  generalLedger: string
  refreshInterval: DurationPatternDateEnum
  calculationMode: InterestCalculationModeEnum
  // ADDED INSIDE TRASNFORM LOGIC
  administration?: string
  client?: string // iri reference to Client
}

export function interestCalculationModeOptions(t: (v: string) => string): {
  label: string
  value: InterestCalculationModeEnum
}[] {
  return Object.values(InterestCalculationModeEnum)
    .filter(value => typeof value === 'number')
    .map(value => ({
      label: t(`accounting.interestCalculationMode.options.${value}`),
      value:
                value as InterestCalculationModeEnum,
    }))
}
