import type { InferType } from 'yup'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { array, mixed, object, string } from 'yup'
import { emailRegex } from '../../utils'
import { createFileValidator, validImageExtensions } from '../../utils/vee-validate.helpers'
import { addressRawSchema } from '../address'
import { ProfileAdditionalContactInformationContactTypeEnum } from './profile.interface'

export function profileRawSchema(t: (v: string) => string, {
  numberRequired = false,
}: {
  numberRequired?: boolean
} = {}) {
  const MAX_IMAGE_SIZE = 20 * 1024 * 1024
  console.log('numberRequired', numberRequired)
  return {
    picture: createFileValidator({ maxSize: MAX_IMAGE_SIZE, validExtensions: validImageExtensions, t }),
    name: string().required(),
    emailAddress: string().test('validate-email', (value, ctx) => {
      if (!value)
        return ctx.createError({ message: t('common.forms.required') })
      if (!emailRegex.test(value))
        return ctx.createError({ message: t('common.forms.email') })
      return true
    }),
    phoneNumberCountryCode: string().default('NL'),
    phoneNumber: string().test('validate-phone', (value, ctx) => {
      if (!value)
        return true
      if (!isValidPhoneNumber(value, ctx.parent.phoneNumberCountryCode))
        return ctx.createError({ message: t('common.forms.phone') })
      return true
    }).optional(),
    mobilePhoneNumberCountryCode: string().default('NL'),
    mobilePhoneNumber: string().test('validate-phone', (value, ctx) => {
      if (!value)
        return true
      if (!isValidPhoneNumber(value, ctx.parent.mobilePhoneNumberCountryCode))
        return ctx.createError({ message: t('common.forms.phone') })
      return true
    }).optional(),
    number: numberRequired ? string().required() : string().nullable(),
    locale: string().required(),
    timezone: string().required(),
    tenant: string().required(),
    profile_additional_contact_information: array().of(object({
      '@id': string().optional(),
      'contactType': mixed().oneOf(Object.values(ProfileAdditionalContactInformationContactTypeEnum).filter(v => typeof v === 'number')).required(),
      'information': string().required().max(255).test('validate-contact-info', (value, ctx) => {
        const contactType = ctx.parent.contactType

        // For email type
        if (contactType === ProfileAdditionalContactInformationContactTypeEnum.EMAIL_ADDRESS) {
          if (!emailRegex.test(value))
            return ctx.createError({ message: t('common.forms.email') })
        }

        // For phone number types
        if (contactType === ProfileAdditionalContactInformationContactTypeEnum.PHONE_NUMBER) {
          const countryCode = ctx.parent.countryCode || 'NL'
          if (!isValidPhoneNumber(value, countryCode))
            return ctx.createError({ message: t('common.forms.phone') })
        }

        return true
      }),
      'countryCode': string().nullable(),
    })).default([]),
  }
}

export interface ProfileAdditionalContactInformationRawSchema {
  '@id'?: string
  'contactType': ProfileAdditionalContactInformationContactTypeEnum
  'information': string
  'countryCode': string | null
}

export interface ProfileAdditionalContactInformationWrite {
  contactType: ProfileAdditionalContactInformationContactTypeEnum
  information: string
}

export function profileObjectSchema(t: (v: string) => string, profileOptions: {
  numberRequired?: boolean
} = {}) {
  console.log('profileOptions', profileOptions)
  return object({
    ...profileRawSchema(t, profileOptions),
    ...addressRawSchema(t),
  })
}

export type ProfileObjectSchema = InferType<ReturnType<typeof profileObjectSchema>>
