import type { HydraContext, HydraTimeBased } from '../../../common'
import type { EntityReferenceValueObjectSchema, StringTemplateObjectSchema } from '../documents'
import type { UserSnapshotHydraItem } from '../user'
import type { EmailEventEnum, EmailSubEventEnum, SmsEventEnum, SmsSubEventEnum } from './queue_job.options.ts'
import { CorrespondenceTemplateContextEnum } from '../correspondences'

export interface AbstractQueueJobHydraItem
  extends HydraContext,
  HydraTimeBased {
  createdBy: UserSnapshotHydraItem
  id: number
  locale: string
  body: StringTemplateObjectSchema
  baseContext: EntityReferenceValueObjectSchema
  subContext: EntityReferenceValueObjectSchema[]
  automatic: boolean
  executedBy?: string
  dispatchedAt?: string
  executedAt?: string
  batched: boolean
}

export type AbstractQueueJobHydraCollectionItem = Omit<AbstractQueueJobHydraItem, 'body'>

export interface QueueStats {
  mail_job: {
    open: number
    done: number
  }
  print_job: {
    open: number
    done: number
  }
  sms_job: {
    open: number
    done: number
  }
}

export type PrintQueueJobHydraItem = AbstractQueueJobHydraItem
export type PrintQueueJobHydraCollectionItem =
    AbstractQueueJobHydraCollectionItem

export type EmailQueueJobHydraItem = AbstractQueueJobHydraItem & {
  emailTemplate: string
  blindCarbonCopyCount: number
  carbonCopyCount: number
  embeddedPartCount: number
  partCount: number
  recipientCount: number
}
export type EmailQueueJobHydraCollectionItem =
  AbstractQueueJobHydraCollectionItem & {
    blindCarbonCopyCount: number
    carbonCopyCount: number
    embeddedPartCount: number
    partCount: number
    recipientCount: number
  }

export type SmsQueueJobHydraItem = AbstractQueueJobHydraItem & {
  textTemplate: string
}
export type SmsQueueJobHydraCollectionItem =
    AbstractQueueJobHydraCollectionItem

interface AbstractEmailEventDocument extends HydraContext {
  date: string
  email: string
  metadata: string[]
  tags: string[]
  name: string
  payload: string[]
}

export interface EmailEventLogHydraItem extends HydraContext, HydraTimeBased {
  recipient: string
  event: EmailEventEnum
  eventSubType: EmailSubEventEnum
  eventData: AbstractEmailEventDocument
}

export enum ViewContextEnum {
  UserInvite = 'user/invite',
  InvoiceSend = 'invoice/send',
  InvoiceReminder = 'invoice/reminder',
  CaseFileDebtorOrganization = 'case_file/debtor/organization',
  CaseFileDebtorIndividual = 'case_file/debtor/individual',
}

export type AbstractQueueJobHydraItemCombined = EmailQueueJobHydraItem | SmsQueueJobHydraItem | PrintQueueJobHydraItem

export const ViewContextMapping: Record<CorrespondenceTemplateContextEnum, ViewContextEnum[]> = {
  [CorrespondenceTemplateContextEnum.CASE_FILE]: [
    ViewContextEnum.CaseFileDebtorOrganization,
    ViewContextEnum.CaseFileDebtorIndividual,
  ],
  [CorrespondenceTemplateContextEnum.CASE_FILE_INVOICE]: [],
  [CorrespondenceTemplateContextEnum.CLIENT]: [],
  [CorrespondenceTemplateContextEnum.ORGANIZATION]: [],
  [CorrespondenceTemplateContextEnum.INDIVIDUAL]: [],
  [CorrespondenceTemplateContextEnum.PAYMENT_REQUEST]: [],
  [CorrespondenceTemplateContextEnum.PAYMENT]: [],
  [CorrespondenceTemplateContextEnum.TASK]: [],
  [CorrespondenceTemplateContextEnum.PAYOUT]: [],
  [CorrespondenceTemplateContextEnum.PAYOUT_BATCH]: [],
  [CorrespondenceTemplateContextEnum.INVOICE]: [
    ViewContextEnum.InvoiceSend,
    ViewContextEnum.InvoiceReminder,
  ],
  [CorrespondenceTemplateContextEnum.AUTOMATION]: [],
  [CorrespondenceTemplateContextEnum.USER]: [
    ViewContextEnum.UserInvite,
  ],
  [CorrespondenceTemplateContextEnum.PROFILE]: [],

}

export function viewContextEnumOptions(context: CorrespondenceTemplateContextEnum, t: ReturnType<typeof useI18n>['t']) {
  return ViewContextMapping[context].map(value => ({
    value,
    label: t(`queue_jobs.viewContext.options.${value}`),
  }))
}

interface AbstractSmsEventDocument extends HydraContext {
  recipient: string
  from: string
  payload: string[]
}

export interface SmsEventLogHydraItem extends HydraContext, HydraTimeBased {
  recipient: string
  event: SmsEventEnum
  eventSubType: SmsSubEventEnum
  eventData: AbstractSmsEventDocument
}

export interface showQueueInterface {
  defaultRecipientReference?: string
  defaultLocaleReference?: string
  defaultRecipientFixedValue?: string
  getLocaleValue?: (item: HydraContext) => string | undefined
  viewContext?: ViewContextEnum
  getAdministrationIri?: (item: HydraContext) => string | undefined
}
