import type { Command } from '@ckeditor/ckeditor5-core'
import type { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic'
import type { DecoupledEditor } from '@ckeditor/ckeditor5-editor-decoupled'

import type { InlineEditor } from '@ckeditor/ckeditor5-editor-inline'
import { createInjectionState } from '@vueuse/shared'
// useSourceViewer.ts
import { ref } from 'vue'

const [useProvideTwigHelpers, useTwigHelpersRaw] = createInjectionState(
  () => {
    // state
    const insertTwigVariableCommand = ref<Command | undefined>(undefined)

    const setupTwig = (edit: DecoupledEditor | ClassicEditor | InlineEditor) => {
      insertTwigVariableCommand.value = edit.commands.get('insertTwigVariable')

      edit.commands.get('insertTwigVariable')?.on('execute', () => {
        console.log('The editing widget of the variable was clicked.')
      })
    }
    return {
      setupTwig,
      insertTwigVariableCommand,
    }
  },
)
export { useProvideTwigHelpers }

// If you want to hide `useSourceViewer` and wrap it in default value logic or throw error logic, please don't export `useSourceViewer`
export function useTwigHelpers() {
  const counterStore = useTwigHelpersRaw()
  if (counterStore == null) {
    throw new Error(
      'Please call `useProvideTwigHelpers` on the appropriate parent component',
    )
  }
  return counterStore
}
