import type { useTableInjected } from '../../table'
import type { CustomExportHydraCollectionItem, CustomExportHydraItem } from './custom_exports.interface.ts'
import type { SonnerProps } from './useMercureItemsProgress.ts'
import { useAuthStore } from '../../auth'
import { useMercureItems } from '../../mercure'
import { useMercureItemsProgress } from './useMercureItemsProgress.ts'

export const useCustomExportsStore = createGlobalState(() => {
  const items = ref<CustomExportHydraCollectionItem[]>([])
  const { user } = useAuthStore()

  const loading = ref(false)
  const initialLoading = ref(false)
  const { t } = useI18n()

  const loadDataRef = ref<ReturnType<typeof useTableInjected>['loadData'] | null>(null)

  const setLoadDataRef = (ref: ReturnType<typeof useTableInjected>['loadData']) => {
    loadDataRef.value = ref
  }
  const router = useRouter()

  const { onAddOrUpdate, onDelete } = useMercureItemsProgress<CustomExportHydraItem>({
    title: t('exports.mercure.title'),
    getSonnerProps: (item, dismissToast) => {
      const defaultProps: SonnerProps = {
        message: t('exports.mercure.base_message', { id: item.id }),
        value: 0,
        isComplete: false,
        violations: {} as Record<string, string[]>,
        hideViolations: true,
        runningIcon: ['far', 'file-export'],
      }

      // Handle completed states (failed or finished)
      if (item.failedAt || item.finishedAt) {
        defaultProps.value = 100
        defaultProps.dismiss = 5000

        if (item.finishedAt) {
          defaultProps.isComplete = true
          defaultProps.message = t('exports.mercure.finished_message', { id: item.id })
          defaultProps.button = {
            icon: 'pi pi-eye',
            class: 'mt-2',
            size: 'small',
            label: t('exports.mercure.finished_button'),
            onClick: () => {
              router.replace({
                name: 'exports',
                query: {
                  'id[]': item.id,
                },
              })
              dismissToast()
            },
          }
          defaultProps.hideProgressBar = true
        }

        if (item.failedAt) {
          defaultProps.message = t('exports.mercure.failed_message', { id: item.id })
          defaultProps.violations = {
            [t('exports.mercure.failed_key')]: [t('exports.mercure.failed_value')],
          }
        }
      }
      // Handle in-progress state
      else if (item.startedAt) {
        defaultProps.message = t('exports.mercure.started_message', { id: item.id })
      }

      return defaultProps
    },
  })

  const encoded = encodeURIComponent(`/api/custom_exports/{id}`)
  const topic = `/users/${user.value?.id}?topic=${encoded}`

  useMercureItems<CustomExportHydraItem>({
    topic,
    items,
    loadData: loadDataRef.value ?? undefined,
    onAddOrUpdate,
    onDelete,
  })

  function reset() {
    loading.value = false
    initialLoading.value = false
    items.value = []
  }

  return {
    reset,
    setLoadDataRef,
    items,
    loading,
    initialLoading,
    onAddOrUpdate,
    onDelete,
  }
})
