import type { ClientSettingHydraItem, TenantSettingHydraItem } from './settings.schema.ts'
import { StorageSerializers, useStorage } from '@vueuse/core'
import api from '../../api/api'
import { PermissionAttributes, usePermissions } from '../permissions'
import { useTenantsStore } from '../tenants'

export const useGlobalClientSettings = createGlobalState(() => {
  const globalSettings = useStorage<ClientSettingHydraItem | null>('global-setting', null, undefined, { serializer: StorageSerializers.object })
  const tenantSettings = useStorage<TenantSettingHydraItem | null>('global-setting', null, undefined, { serializer: StorageSerializers.object })
  const { firstTenant } = useTenantsStore()
  const { hasPermission } = usePermissions()
  const loading = ref(false)
  const allowReadCaseFileCloseReasons = computed(() =>
    hasPermission(
      PermissionAttributes.GLOBAL.OPERATION.CLIENT_SETTING.READ_GLOBAL,
    ))

  const allowReadTenantSettings = computed(() =>
    firstTenant.value
      ? hasPermission(
          PermissionAttributes.CONTEXT.TENANT.OPERATION.TENANT_SETTING.READ_VIA_TENANT,
          {
            TENANT: firstTenant.value['@id'],
          },
        )
      : false)

  async function setGlobalSettings(): Promise<void> {
    if (!allowReadCaseFileCloseReasons.value) {
      globalSettings.value = null
      return
    }
    const { data } = await api.get<ClientSettingHydraItem>('/api/client_settings/global')
    globalSettings.value = data
  }

  async function setTenantSettings(): Promise<void> {
    if (!allowReadTenantSettings.value || !firstTenant.value) {
      tenantSettings.value = null
      return
    }
    const { data } = await api.get<TenantSettingHydraItem>(`/api/tenants/${firstTenant.value.id}/settings`)
    tenantSettings.value = data
  }

  const hasAppSubscription = computed(() => !!tenantSettings.value?.appSubscription)
  return {
    setGlobalSettings,
    setTenantSettings,
    globalSettings,
    hasAppSubscription,
    loading,
  }
})
