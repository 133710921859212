// useSourceViewer.ts
import { createInjectionState } from '@vueuse/shared'
import { ref } from 'vue'

const [useProvideSourceViewer, useSourceViewerRaw] = createInjectionState(
  () => {
    // state
    const visible = ref(false)
    const showSource = () => {
      visible.value = true
    }

    const hideSource = () => {
      visible.value = false
    }

    return { showSource, hideSource, visible }
  },
)
export { useProvideSourceViewer }
// If you want to hide `useSourceViewer` and wrap it in default value logic or throw error logic, please don't export `useSourceViewer`
export function useSourceViewer() {
  const counterStore = useSourceViewerRaw()
  if (counterStore == null) {
    throw new Error(
      'Please call `useProvideSourceViewer` on the appropriate parent component',
    )
  }
  return counterStore
}
