import type { HydraCollection } from '../../../common'
import type { TimeTrackingSessionHydraCollectionItem } from './time_tracking_session.interface'
import { StorageSerializers, useStorage } from '@vueuse/core'
import { PermissionAttributes, useAuthStore, usePermissions } from '../..'
import { api } from '../../api'

export const useTimeTrackingStore = createGlobalState(() => {
  const timeTrackingSessions = useStorage<Record<string, TimeTrackingSessionHydraCollectionItem> | null>('timeTrackingSessions', null, undefined, { serializer: StorageSerializers.object })

  const timeTrackingSessionList = computed(() => {
    return timeTrackingSessions.value ? Object.values(timeTrackingSessions.value) : []
  })

  const activeTimeTrackingSession = computed(() => {
    return timeTrackingSessionList.value.find(session => !session.endTimeAt)
  })

  const items = ref<TimeTrackingSessionHydraCollectionItem[]>([])

  const loading = ref(false)
  const initialLoading = ref(false)
  const timeTrackingActionLoading = ref(false)

  function reset() {
    loading.value = false
    initialLoading.value = false
    timeTrackingActionLoading.value = false
    items.value = []
    timeTrackingSessions.value = null
  }

  const { hasPermission } = usePermissions()

  const { user } = useAuthStore()

  const allowReadTimeTrackingSessions = computed(() =>
    hasPermission(
      PermissionAttributes.GLOBAL.OPERATION.TIME_TRACKING_SESSION
        .READ_COLLECTION,
    ),
  )

  async function startTimeTracking(caseFileId: string): Promise<void> {
    if (!allowReadTimeTrackingSessions.value || !user.value)
      return

    timeTrackingActionLoading.value = true
    try {
      await api.post(`/api/case_files/${caseFileId}/time_tracking_sessions/start`)
      await setTimeTrackingSessions()
    }
    finally {
      timeTrackingActionLoading.value = false
    }
  }

  async function stopTimeTracking(caseFileId: string): Promise<void> {
    if (!allowReadTimeTrackingSessions.value || !user.value)
      return

    timeTrackingActionLoading.value = true
    try {
      await api.post(`/api/case_files/${caseFileId}/time_tracking_sessions/stop`)
      await setTimeTrackingSessions()
    }
    finally {
      timeTrackingActionLoading.value = false
    }
  }

  async function setTimeTrackingSessions(): Promise<void> {
    if (!allowReadTimeTrackingSessions.value || !user.value) {
      timeTrackingSessions.value = null
      return
    }

    const initialParams = new URLSearchParams()
    initialParams.set('order[id]', 'desc')
    await api.get<HydraCollection<TimeTrackingSessionHydraCollectionItem>>(`/api/users/${user.value.id}/time_tracking_sessions`, {
      params: initialParams,
    }).then((response) => {
      timeTrackingSessions.value = response.data.member.reduce((acc, item) => {
        acc[item['@id']] = item
        return acc
      }, {} as Record<string, TimeTrackingSessionHydraCollectionItem>)
    })
  }

  const timeTrackingSessionsOptions = computed(() => {
    return timeTrackingSessions.value
      ? Object.values(timeTrackingSessions.value).map(session => ({
          label: `${session.startTimeAt} - ${session.endTimeAt}`,
          value: session['@id'],
        }))
      : []
  })

  return {
    timeTrackingSessionsOptions,
    allowReadTimeTrackingSessions,
    setTimeTrackingSessions,
    reset,
    items,
    activeTimeTrackingSession,
    timeTrackingSessionList,
    timeTrackingSessions,
    loading,
    initialLoading,
    timeTrackingActionLoading,
    startTimeTracking,
    stopTimeTracking,
  }
})
