import type { HydraContext, HydraTimeBased } from '../../../common'
import type { MediaObjectHydraItem } from '../media_objects'
import type { ApplicationStatusEnum } from './application.state.ts'

export enum AppSubscriptionAuthDocumentTypeEnum {
  OAuth = 'OAuthAppDataDocument',
}

export enum AppSubscriptionDataTypeEnum {
  ExactInternal = 'AppSubscription.ExactInternal.Data',
}

export enum AppSubscriptionExactDivisionSettingEnum {
  NONE = 1,
  GENERIC = 2,
  DIVERGENT = 3,
  SHIFTED = 4,
  DELIVERY_OUTSIDE_EU = 5,
  GOODS_INSIDE_EU = 6,
  SERVICE_INSIDE_EU = 7,
  DISTANCE_SELL_INSIDE_EU = 8,
}

export function appSubscriptionExactDivisionSettingEnumOptions(t: ReturnType<typeof useI18n>['t']): {
  label: string
  value: number
}[] {
  return Object.values(AppSubscriptionExactDivisionSettingEnum).filter(value => typeof value === 'number').map(value => ({
    label: t(`applications.subscription.vatType.options.${value}`),
    value,
  }))
}

export interface AppSubscriptionExactData {
  '@type': 'DataDocument'
  '@id': string
  '#type': AppSubscriptionDataTypeEnum.ExactInternal
  'divisionSettings': {
    '@id': string
    '@type': 'DivisionSettingDataDocument'
    'thirdPartyReference': string
    'vatCodeMapping': {
      '@id': string
      '@type': 'VatCodeMappingDataDocument'
      'vatCodeThirdPartyReference': string
      'vatType': number
    }[]
  }[]
}

export interface OAuthAppDataDocument {
  '#type': AppSubscriptionAuthDocumentTypeEnum.OAuth
  '@type': 'OAuthAppDataDocument'
  '@id': string
  'clientIdentifier': string
  'redirectUri': string
  'scopes': string[]
}

enum AppCategoryEnum {
  ACCOUNTANCY = 0,
  CRM = 1,
  PAYMENT_PROVIDER = 2,
  OTHER = 100,
}

export function getRandomAppCategory(): AppCategoryEnum {
  const categories = Object.values(AppCategoryEnum).filter(v => typeof v === 'number') as AppCategoryEnum[]
  return categories[Math.floor(Math.random() * categories.length)]
}

const AppCategories = Object.values(AppCategoryEnum).filter(value => typeof value === 'number') as AppCategoryEnum[]

export interface AppHydraItem extends HydraContext {
  '@type': 'App'
  'id': number
  'name': string
  'description': string | null
  'category': AppCategoryEnum | null
  'logo'?: MediaObjectHydraItem
  'status': ApplicationStatusEnum
  'appSubscriptionDataType': AppSubscriptionDataTypeEnum | null
  'appSubscriptionAuthDataType': AppSubscriptionAuthDocumentTypeEnum | null
  'data'?: OAuthAppDataDocument
  'authData'?: any
}

export interface AppCenterHydraItem extends AppHydraItem {
  hasSubscription: boolean
  loading: boolean
}

export interface AppSubscriptionHydraItem extends HydraContext, HydraTimeBased {
  '@type': 'AppSubscription'
  'id': number
  'app': AppHydraItem
  'data'?: AppSubscriptionExactData
}

export interface AppCenterSubscriptionHydraItem extends AppSubscriptionHydraItem {
  loading: boolean
}

export interface AppSubscriptionOauthV2Callback {
  code: string
  state: AppSubscriptionHydraItem
}

export interface ApplicationCategory extends HydraContext {
  id: number
  name: string
}

export { AppCategories, AppCategoryEnum }
