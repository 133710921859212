import type { RouteRecordRaw } from 'vue-router'
import CollectionRoutes from '@features/collection/CollectionRoutes.ts'
import DashboardRoutes from '@features/dashboard/DashboardRoutes.ts'
import FinancialRoutes from '@features/financial/FinancialRoutes.ts'
import ManageRoutes from '@features/manage/ManageRoutes.ts'
import SettingsRoutes from '@features/settings/SettingsRoutes.ts'
import TasksRoutes from '@features/tasks/TasksRoutes.ts'
import { PermissionAttributes, PermissionResources, UserRole } from 'lib'

export const MainRoutes: RouteRecordRaw[] = [
  {
    path: '',
    redirect: { name: 'dashboard' },
  },
  {
    path: '/dashboard',
    children: DashboardRoutes,
    meta: {
      navbar: {
        name: 'dashboard',
        icon: 'pi-home',
      },
    },
  },
  {
    path: 'user_bookmarks',
    name: 'user_bookmarks',
    component: () =>
      import('lib/src/components/Features/user_bookmarks/UserBookmarks.vue'),
  },
  {
    path: 'time_tracking_sessions',
    name: 'time_tracking_sessions',
    component: () =>
      import('lib/src/components/Features/time_tracking_sessions/TimeTrackingSessions.vue'),
  },
  {
    path: '/tasks',
    children: TasksRoutes,
    meta: {
      permissionResources: [PermissionResources.TASK],
      navbar: {
        name: 'tasks',
        icon: ['far', 'list-check'],
      },
    },
  },
  {
    path: 'casefiles',
    name: 'casefiles',
    meta: {
      permissionResources: [PermissionResources.CASE_FILE],
      navbar: {
        name: 'casefiles',
        icon: ['far', 'envelope-open-dollar'],
      },
    },
    component: () =>
      import('lib/src/components/Features/casefiles/CaseFiles.vue'),
  },
  {
    path: 'casefiles/:id',
    name: 'casefile',
    meta: {
      permissionResources: [PermissionResources.CASE_FILE],
      parent: 'casefiles',
    },
    component: () =>
      import('lib/src/components/Features/casefiles/edit/CaseFileEdit.vue'),
  },
  {
    path: 'casefiles/new',
    name: 'casefiles_new',
    meta: {
      permissionsFuzzy: [
        PermissionAttributes.CONTEXT.CLIENT.OPERATION.CASE_FILE.CREATE,
      ],
      parent: 'casefiles',
    },
    component: () =>
      import(
        'lib/src/components/Features/casefiles/create/CaseFileCreate.vue'
      ),
  },
  {
    path: 'clients',
    name: 'clients',
    meta: {
      permissionResources: [PermissionResources.CLIENT],
      navbar: {
        name: 'clients',
        icon: ['far', 'people-pants'],
      },
    },
    component: () => import('@features/clients/Clients.vue'),
  },
  {
    path: 'clients/:id',
    name: 'client',
    meta: {
      permissionResources: [PermissionResources.CLIENT],
      parent: 'clients',
    },
    component: () => import('@features/clients/edit/ClientEdit.vue'),
  },
  {
    path: 'clients/:clientId/user/:userId',
    name: 'client-user',
    meta: {
      permissionResources: [PermissionResources.CLIENT],
      parent: 'clients',
    },
    component: () => import('lib/src/components/Features/user/edit/UserEdit.vue'),
  },
  {
    path: 'clients/new',
    name: 'client_new',
    meta: {
      permissions: [
        PermissionAttributes.GLOBAL.OPERATION.CLIENT.CREATE,
      ],
      parent: 'clients',
    },
    component: () => import('@features/clients/create/ClientCreate.vue'),
  },
  {
    path: 'debtors',
    name: 'debtors',
    meta: {
      permissionsFuzzy: [
        PermissionAttributes.CONTEXT.CLIENT.OPERATION.ORGANIZATION.READ_COLLECTION_VIA_CLIENT,
        PermissionAttributes.CONTEXT.CLIENT.OPERATION.INDIVIDUAL.READ_COLLECTION_OWNING_VIA_CLIENT,
      ],
      navbar: {
        name: 'debtors',
        icon: ['far', 'people-pants-simple'],
        todo: true,
      },
    },
    component: () => import('lib/src/components/Features/debtor/Debtors.vue'),
  },
  {
    path: 'debtors/:type/:id',
    name: 'debtor',
    meta: {
      permissionResources: [PermissionResources.ORGANIZATION, PermissionResources.INDIVIDUAL],
      parent: 'debtors',
    },
    component: () =>
      import('lib/src/components/Features/debtor/edit/DebtorEdit.vue'),
  },
  {
    path: 'debtors/new',
    name: 'debtor_new',
    meta: {
      permissionsFuzzy: [
        PermissionAttributes.CONTEXT.CLIENT.OPERATION.ORGANIZATION.CREATE,
        PermissionAttributes.CONTEXT.CLIENT.OPERATION.INDIVIDUAL.CREATE,
      ],
      parent: 'debtors',
    },
    component: () =>
      import('lib/src/components/Features/debtor/create/DebtorCreate.vue'),
  },
  {
    path: '/collection',
    children: CollectionRoutes,
    meta: {
      navbar: {
        width: '200px',
        name: 'collection',
        icon: ['far', 'envelope-open-dollar'],
        hasChildren: true,
      },
    },
  },
  {
    path: '/financial',
    children: FinancialRoutes,
    meta: {
      navbar: {
        width: '250px',
        name: 'financial',
        icon: ['far', 'coins'],
        hasChildren: true,
      },
    },
  },
  {
    path: '/manage',
    children: ManageRoutes,
    meta: {
      navbar: {
        name: 'manage',
        icon: ['far', 'toolbox'],
        hasChildren: true,
      },
    },
  },
  {
    path: 'automations',
    name: 'automations',
    meta: {
      permissionResources: [PermissionResources.AUTOMATION],
      navbar: {
        name: 'automations',
        icon: ['far', 'arrow-progress'],
        todo: true,
      },
    },
    component: () => import('lib/src/components/Features/automations/Automations.vue'),
  },
  {
    path: 'automations/:id',
    meta: {
      permissions: [PermissionAttributes.GLOBAL.OPERATION.AUTOMATION.READ_SINGLE],
    },
    name: 'automation',
    component: () => import('../../../../packages/lib/src/components/Features/automations/edit/AutomationV1.vue'),
  },
  {
    path: 'automationsv1/:id',
    meta: {
      permissions: [PermissionAttributes.GLOBAL.OPERATION.AUTOMATION.READ_SINGLE],
    },
    name: 'automationv1',
    component: () => import('../../../../packages/lib/src/components/Features/automations/edit/AutomationV1.vue'),
  },
  {
    path: 'automations/new',
    meta: {
      permissions: [PermissionAttributes.GLOBAL.OPERATION.AUTOMATION.CREATE],
      type: 'create',
    },
    name: 'automations_new',
    component: () => import('../../../../packages/lib/src/components/Features/automations/edit/AutomationV1.vue'),
  },
  {
    path: '/settings',
    children: SettingsRoutes,
    meta: {
      navbar: {
        name: 'settings',
        icon: 'pi-cog',
        hasChildren: true,
      },
    },
  },
  {
    path: 'messenger',
    name: 'messenger',
    meta: {
      type: 'messenger',
      roles: [UserRole.ROLE_ADMIN],
      navbar: {
        name: 'messenger',
        icon: ['far', 'hourglass-start'],
        hasChildren: false,
      },
    },
    component: () => import('@features/messenger/Messenger.vue'),
  },
  {
    path: '/:notFound',
    component: () => import('@components/NotFound/NotFound.vue'),
  },
  {
    path: '/access_denied',
    name: 'access_denied',
    component: () => import('lib/src/components/AccessDenied/AccessDenied.vue'),
  },
  {
    name: 'OAuthV2Callback',
    path: '/oauth/v2/callback',
    component: () => import('@features/callback/OAuthv2callback.vue'),
  },
]

export default MainRoutes
