import type { HydraContext, HydraTimeBased } from '../../../common'
import type { EmailTemplateHydraItem } from '../email_templates'
import type { PdfTemplateHydraItem } from '../pdf_templates'
import type { ViewContextEnum } from '../queue_job'
import type { TextTemplateHydraItem } from '../text_templates'

export enum CorrespondenceTemplateContextEnum {
  CASE_FILE = 1,
  CASE_FILE_INVOICE = 2,
  CLIENT = 3,
  ORGANIZATION = 4,
  INDIVIDUAL = 5,
  PAYMENT_REQUEST = 6,
  PAYMENT = 7,
  TASK = 8,
  PAYOUT = 9,
  PAYOUT_BATCH = 10,
  INVOICE = 11,
  AUTOMATION = 12,
  USER = 13,
  PROFILE = 14,
}

export function correspondenceTemplateContextOptions(
  t: (v: string) => string,
): {
    label: string
    value: CorrespondenceTemplateContextEnum
  }[] {
  return Object.values(CorrespondenceTemplateContextEnum)
    .filter(v => typeof v === 'number')
    .map(v => ({
      label: t(`settings.communications.context.options.${v}`),
      value: v as CorrespondenceTemplateContextEnum,
    }))
}

export enum PaperSizeEnum {
  X4A0 = '4a0',
  X2A0 = '2a0',
  A0 = 'a0',
  A1 = 'a1',
  A2 = 'a2',
  A3 = 'a3',
  A4 = 'a4',
  A5 = 'a5',
  A6 = 'a6',
  A7 = 'a7',
  A8 = 'a8',
  A9 = 'a9',
  A10 = 'a10',
  B0 = 'b0',
  B1 = 'b1',
  B2 = 'b2',
  B3 = 'b3',
  B4 = 'b4',
  B5 = 'b5',
  B6 = 'b6',
  B7 = 'b7',
  B8 = 'b8',
  B9 = 'b9',
  B10 = 'b10',
  C0 = 'c0',
  C1 = 'c1',
  C2 = 'c2',
  C3 = 'c3',
  C4 = 'c4',
  C5 = 'c5',
  C6 = 'c6',
  C7 = 'c7',
  C8 = 'c8',
  C9 = 'c9',
  C10 = 'c10',
  RA0 = 'ra0',
  RA1 = 'ra1',
  RA2 = 'ra2',
  RA3 = 'ra3',
  RA4 = 'ra4',
  SRA0 = 'sra0',
  SRA1 = 'sra1',
  SRA2 = 'sra2',
  SRA3 = 'sra3',
  SRA4 = 'sra4',
  LETTER = 'letter',
  HALF_LETTER = 'half-letter',
  LEGAL = 'legal',
  LEDGER = 'ledger',
  TABLOID = 'tabloid',
  EXECUTIVE = 'executive',
  FOLIO = 'folio',
  COMMERCIAL_10_ENVELOPE = 'commercial #10 envelope',
  CATALOG_10_HALF_ENVELOPE = 'catalog #10 1/2 envelope',
  X85_X_11 = '8.5x11',
  X85_X_14 = '8.5x14',
  X11_X_17 = '11x17',
}

export function paperSizeOptions(t: (v: string) => string): {
  label: string
  value: PaperSizeEnum
}[] {
  return Object.values(PaperSizeEnum).map(v => ({
    label: t(`settings.communications.paperSize.options.${v}`),
    value: v as PaperSizeEnum,
  }))
}

export interface CorrespondenceTemplateMarkupHydraItem
  extends HydraContext,
  HydraTimeBased {
  text: string // iri of the template
  locale: string
  markup: string
}

export interface CorrespondenceTemplateContentIdentifierHydraItem
  extends HydraContext,
  HydraTimeBased {
  name: string // iri of the template
  locale: string
  contentIdentifier: string
}

export type CorrespondenceTemplateContentIdentifierHydraCollectionItem = CorrespondenceTemplateContentIdentifierHydraItem

export type CorrespondenceTemplateContentIdentifierWrite = Pick<
  CorrespondenceTemplateContentIdentifierHydraItem,
    'locale' | 'contentIdentifier' | 'name'
>

export type CorrespondenceTemplateMarkupWrite = Pick<
  CorrespondenceTemplateMarkupHydraItem,
    'locale' | 'markup'
>
export type CorrespondenceTemplateMarkupWriteCreate = Pick<
  CorrespondenceTemplateMarkupHydraItem,
    'locale' | 'markup'
> & {
  template: string
}

export interface CorrespondenceTemplateInterface {
  baseContext: CorrespondenceTemplateContextEnum
  administration?: string | null
  subContext?: CorrespondenceTemplateContextEnum
  defaultMarkup: CorrespondenceTemplateMarkupHydraItem
  markups: CorrespondenceTemplateMarkupHydraItem[]
  name: string
  batchable?: boolean
  viewContext?: ViewContextEnum[]
}

export type CorrespondenceTemplateInterfaceHydraItem =
    CorrespondenceTemplateInterface & HydraContext & HydraTimeBased

export type CorrespondenceTemplateWriteCreate = Pick<
  EmailTemplateHydraItem,
    'baseContext' | 'name' | 'viewContext' | 'batchable' | 'administration'
> & {
  defaultMarkup: {
    markup: string
    locale: string
  }
  subContext?: CorrespondenceTemplateContextEnum | null
}

export type CorrespondenceTemplateWriteUpdate = Pick<
  EmailTemplateHydraItem,
    'baseContext' | 'name' | 'viewContext' | 'batchable' | 'administration'
> & {
  subContext?: CorrespondenceTemplateContextEnum | null
}

export enum ReceiverTypeEnum {
  DEBTOR = 1,
  CLIENT = 2,
  USER = 3,
}

export function ReceiverTypeOptions(t: (v: string) => string): {
  label: string
  value: ReceiverTypeEnum
}[] {
  return Object.values(ReceiverTypeEnum).map(v => ({
    label: t(`settings.communications.receiverType.options.${v}`),
    value: v as ReceiverTypeEnum,
  }))
}

export type CorrespondenceTemplateHydraItemCombined =
  | EmailTemplateHydraItem
  | PdfTemplateHydraItem
  | TextTemplateHydraItem

export function convertBaseContextIriToEnum(baseContextIri?: string | null): CorrespondenceTemplateContextEnum | null {
  if (!baseContextIri)
    return null

  if (baseContextIri.includes('/case_files/'))
    return CorrespondenceTemplateContextEnum.CASE_FILE
  if (baseContextIri.includes('/case_file_invoice/'))
    return CorrespondenceTemplateContextEnum.CASE_FILE_INVOICE
  if (baseContextIri.includes('/clients/'))
    return CorrespondenceTemplateContextEnum.CLIENT
  if (baseContextIri.includes('/organizations/'))
    return CorrespondenceTemplateContextEnum.ORGANIZATION
  if (baseContextIri.includes('/individuals/'))
    return CorrespondenceTemplateContextEnum.INDIVIDUAL
  if (baseContextIri.includes('/payment_requests/'))
    return CorrespondenceTemplateContextEnum.PAYMENT_REQUEST
  if (baseContextIri.includes('/payments/'))
    return CorrespondenceTemplateContextEnum.PAYMENT
  if (baseContextIri.includes('/tasks/'))
    return CorrespondenceTemplateContextEnum.TASK
  if (baseContextIri.includes('/payouts/'))
    return CorrespondenceTemplateContextEnum.PAYOUT
  if (baseContextIri.includes('/payout_batch'))
    return CorrespondenceTemplateContextEnum.PAYOUT_BATCH
  if (baseContextIri.includes('/invoices/'))
    return CorrespondenceTemplateContextEnum.INVOICE
  if (baseContextIri.includes('/automations/'))
    return CorrespondenceTemplateContextEnum.AUTOMATION
  if (baseContextIri.includes('/users/'))
    return CorrespondenceTemplateContextEnum.USER
  if (baseContextIri.includes('/profiles/'))
    return CorrespondenceTemplateContextEnum.PROFILE

  return null
}

export function convertCorrespondenceTemplateContextEnumToTranslationsKey(
  context: CorrespondenceTemplateContextEnum,
): string {
  switch (context) {
    case CorrespondenceTemplateContextEnum.CASE_FILE:
      return 'case_file'
    case CorrespondenceTemplateContextEnum.CASE_FILE_INVOICE:
      return 'case_file_invoice'
    case CorrespondenceTemplateContextEnum.CLIENT:
      return 'client'
    case CorrespondenceTemplateContextEnum.ORGANIZATION:
      return 'organization'
    case CorrespondenceTemplateContextEnum.INDIVIDUAL:
      return 'individual'
    case CorrespondenceTemplateContextEnum.PAYMENT_REQUEST:
      return 'payment_request'
    case CorrespondenceTemplateContextEnum.PAYMENT:
      return 'payment'
    case CorrespondenceTemplateContextEnum.TASK:
      return 'task'
    case CorrespondenceTemplateContextEnum.PAYOUT:
      return 'remittance'
    case CorrespondenceTemplateContextEnum.PAYOUT_BATCH:
      return 'remittance_batch'
    case CorrespondenceTemplateContextEnum.INVOICE:
      return 'invoice'
    case CorrespondenceTemplateContextEnum.AUTOMATION:
      return 'automation'
    case CorrespondenceTemplateContextEnum.USER:
      return 'user'
    case CorrespondenceTemplateContextEnum.PROFILE:
      return 'profile'
    default:
      return ''
  }
}
