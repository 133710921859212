import isEqual from 'lodash-es/isEqual'
import { useConfirm } from 'primevue/useconfirm'
import { mixed } from 'yup'

export const emailRegex
    = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i

export function getDirtyInput<T>(
  isFieldDirty: (v: any) => boolean,
  input: T,
  returnAll = false,
): Partial<T> {
  if (returnAll)
    return input
    // Loop over object and use isFieldDirty to determine wether to return it or not
  return Object.fromEntries(
    Object.entries(input as any).filter(([key]) =>
      isFieldDirty(key as unknown as keyof T),
    ),
  ) as Partial<T>
}

export function useValidateFields({
  validFields,
  values,
  validateField,
}: {
  validFields: any[]
  values: any
  validateField: (field: any) => Promise<{ valid: boolean }>
}) {
  const isValid = ref(false)

  watch(
    () => values,
    async () => {
      let watchValid = true
      for (const field of validFields) {
        const { valid } = await validateField(field)
        if (!valid) {
          watchValid = false
          break
        }
      }
      isValid.value = watchValid
    },
    { deep: true },
  )
  return {
    isValid,
  }
}

export function usePreventDirtyClose() {
  const confirm = useConfirm()
  const { t } = useI18n()
  const isDirty = useIsFormDirty()

  onBeforeRouteLeave((_, __, next) => {
    if (isDirty.value) {
      confirm.require({
        group: 'prevent-back',
        message: t('common.forms.are_your_sure.message'),
        header: t('common.forms.are_your_sure.header'),
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          next()
        },
        reject: () => {
          next(false)
        },
      })
    }
    else {
      next()
    }
  })

  useEventListener(window, 'beforeunload', (evt: BeforeUnloadEvent) => {
    if (isDirty.value)
      evt.preventDefault()
  })
}

export function arrayDirty<
  I extends {
    '@id': string
  },
  L extends {
    '@id'?: string
  },
>(initialItems: I[], items: L[], inputTransformer: (input: I) => L) {
  const created: L[] = []
  const updated: (L & {
    '@id': string
  })[] = []
  if (!items) {
    return {
      created: [],
      updated: [],
      deleted: [],
    }
  }
  const deleted: string[] = []
  const ItemsIds = items.filter(item => item['@id']).map(l => l['@id'])
  const transformedInitialItems = initialItems.map(initItem =>
    inputTransformer(initItem),
  )
  for (const item of items) {
    if (item['@id'] === undefined) {
      created.push(item)
    }
    else {
      const initialLine = transformedInitialItems.find(
        transInitItem => transInitItem['@id'] === item['@id'],
      )
      if (initialLine === undefined)
        continue
      // Now make new object with only keys
      const dirty = Object.entries(item).some(([key, value]) => {
        if (key === '@id')
          return false
        const initItem = initialLine[key as keyof typeof initialLine]
        return !isEqual(value, initItem)
      })
      if (dirty && item['@id'])
        updated.push({ ...item, '@id': item['@id'] })
    }
  }
  for (const initialLine of initialItems) {
    if (!ItemsIds.includes(initialLine['@id'])) {
      deleted.push(initialLine['@id'])
    }
  }
  return {
    created,
    updated,
    deleted,
  }
}

export function isValidFileType({
  file,
  validExtensions,
}: {
  file: File
  validExtensions: string[]
}): boolean {
  return file && validExtensions.includes(file.name.split('.').pop()?.toLowerCase() || '')
}

export function createFileValidator({ maxSize, validExtensions, t }: {
  maxSize: number
  validExtensions: string[]
  t: ReturnType<typeof useI18n>['t']
}) {
  return mixed()
    .nullable()
    .test('is-valid-type', t('common.forms.invalid_image_type'), (value) => {
      if (!value)
        return true
      return isValidFileType({ file: value as File, validExtensions })
    })
    .test('is-valid-size', t('common.forms.max_file_size', { size: `${maxSize / (1024 * 1024)}MB` }), (value) => {
      if (!value)
        return true
      return (value as File).size <= maxSize
    })
}

export const validImageExtensions = ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp']
