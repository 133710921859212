import api from '../api/api'
import useConfigStore from '../config/useConfigStore'
import { useInstanceStore } from '../instance/useInstance'
import { useAuthStore } from './useAuthStore'

export function useLogout() {
  const authStore = useAuthStore()
  const router = useRouter()
  const { config } = useConfigStore()
  const { isPortal } = useInstanceStore()

  async function logout(redirectToLogin = true) {
    if (!config.value)
      throw new Error('Config not set')

    if (redirectToLogin) {
      if (isPortal.value) {
        localStorage.removeItem('selected-brand-type')
      }

      if (import.meta.env.VITE_SKIP_OAUTH === 'true') {
        authStore.reset()
        router.replace('/auth/login').then()
      }
      else {
        const token = authStore.token
        if (token?.value) {
          const formData = new URLSearchParams()
          formData.append('token', token.value)
          formData.append('token_type_hint', 'access_token')

          await api.post(`/api/access_tokens/revoke`, formData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).catch((e) => {
            console.log('[useLogout] - Revoke token error:', e)
          })
        }
        authStore.reset()
        window.open(`${config.value.loginUrl}/logout`, '_self')
      }
    }
  }

  return { logout }
}
