import type { HydraContext } from '../../../common'
import type { AddressHydraItem, AddressWriteInterface } from '../address'
import type { MediaObjectHydraItem } from './../media_objects/media_objects.interface'
import { parsePhoneNumberWithError } from 'libphonenumber-js'

export interface ProfileInterface {
  name: string
  emailAddress: string
  phoneNumber?: string
  tenant: string
  number?: string | null
  mobilePhoneNumber?: string
  locale: string
  picture?: MediaObjectHydraItem
  timezone: string
}

export enum ProfileAdditionalContactInformationContactTypeEnum {
  EMAIL_ADDRESS = 1,
  PHONE_NUMBER = 2,
}

export interface ProfileHydraItem extends ProfileInterface, HydraContext {

}

export type ProfileHydraItemWithAddress = ProfileHydraItem & {
  address: Omit<AddressHydraItem, 'id'>
}

export enum ProfileTypeEnum {
  ORGANIZATION = 1,
  INDIVIDUAL = 2,
}

export const profileTypes = {
  ORGANIZATION: ProfileTypeEnum.ORGANIZATION,
  INDIVIDUAL: ProfileTypeEnum.INDIVIDUAL,
}

export function profileTypeOptions(t: (v: string) => string): {
  label: string
  value: ProfileTypeEnum
}[] {
  return [
    {
      label: t('profile.type.options.1'),
      value: ProfileTypeEnum.ORGANIZATION,
    },
    {
      label: t('profile.type.options.2'),
      value: ProfileTypeEnum.INDIVIDUAL,
    },
  ]
}

export function getCountryCodeFromNumber(number?: string | null): string {
  let country = 'NL'
  if (!number)
    return country
  try {
    const phoneNumber = parsePhoneNumberWithError(number)
    if (phoneNumber?.country) {
      console.log(phoneNumber.country)
      country = phoneNumber.country
    }
  }
  catch (e) {
    console.error(e)
  }
  return country
}

export interface ProfileWrite extends Omit<ProfileInterface, 'picture'> {
  picture?: string
}

export interface ProfileWriteWithAddress extends Omit<ProfileWrite, 'picture'> {
  address: AddressWriteInterface
}

export interface ProfileAdditionalContactInformationHydraItem extends HydraContext {
  contactType: ProfileAdditionalContactInformationContactTypeEnum
  information: string
  countryCode?: string
}
