import type { AxiosResponse } from 'axios'
import { StorageSerializers } from '@vueuse/core'
import { api } from '../api/api'

export const useMercureState = createGlobalState(
  () => {
    const mercureToken = useStorage<string | null>('mercure-sse-token', null, sessionStorage, {
      serializer: StorageSerializers.string,
    })
    const currentPromise = ref<Promise<AxiosResponse<{
      token: string
    }>> | null>(null)

    async function refreshMercureToken() {
      try {
        if (!currentPromise.value) {
          currentPromise.value = api.get<{
            token: string
          }>('/api/sse/token')
        }
        const { data } = await currentPromise.value
        mercureToken.value = data.token
      }
      finally {
        currentPromise.value = null
      }
    }

    return {
      mercureToken,
      refreshMercureToken,
    }
  },
)
