import type { AdministrationHydraCollectionitem } from './administrations.interface.ts'
import { StorageSerializers, useStorage } from '@vueuse/core'
import { getAll } from '../../api'
import { PermissionAttributes, usePermissions } from '../permissions'

export const useAdministrationStore = createGlobalState(() => {
  const administrations = useStorage<Record<
    string,
    AdministrationHydraCollectionitem
  > | null>('administrations', null, undefined, {
    serializer: StorageSerializers.object,
  })

  const preferedAdministration = useStorage<string | null>(
    'preferedAdministration',
    null,
    undefined,
    {
      serializer: StorageSerializers.string,
    },
  )

  const items = ref<AdministrationHydraCollectionitem[]>([])
  const loading = ref(false)
  const initialLoading = ref(false)
  const { hasPermission } = usePermissions()

  function reset() {
    loading.value = false
    initialLoading.value = false
    items.value = []
  }

  const allowAdministrationsRead = computed(() =>
    hasPermission(
      PermissionAttributes.GLOBAL.OPERATION.ADMINISTRATION
        .READ_COLLECTION,
    ),
  )

  async function setAdministrations(): Promise<void> {
    if (
      !allowAdministrationsRead.value
    ) {
      administrations.value = null
      return
    }
    await getAll<AdministrationHydraCollectionitem>({
      url: '/api/administrations',
    }).then((data: AdministrationHydraCollectionitem[]) => {
      administrations.value = data.reduce(
        (acc, item) => {
          acc[item['@id']] = item
          return acc
        },
        {} as Record<string, AdministrationHydraCollectionitem>,
      )
      return data
    })
    if (
      preferedAdministration.value
      && administrations.value
      && !(preferedAdministration.value in administrations.value)
    ) {
      preferedAdministration.value = null
    }
  }

  const administrationsOptions = computed(() => {
    return administrations.value
      ? Object.values(administrations.value).map(status => ({
          label: status.name,
          value: status['@id'],
        }))
      : []
  })
  const administrationsOptionsIdBased = computed(() => {
    return administrations.value
      ? Object.values(administrations.value).map(status => ({
          label: status.name,
          value: status.id,
        }))
      : []
  })

  const getAdministrationOptions = () => {
    return administrations.value
      ? Object.values(administrations.value).map(status => ({
          label: status.name,
          value: status['@id'],
        }))
      : []
  }

  function getAdministrationLabel(iri?: string) {
    if (!administrations.value || !iri)
      return '-'
    const administration = administrations.value[iri]
    return administration ? administration.name : '-'
  }

  function getAdministration(iri: string): AdministrationHydraCollectionitem | null {
    if (!administrations.value)
      return null
    const Administration = administrations.value[iri]
    return Administration || null
  }

  const getPreferedAdministration = computed(() => {
    if (!administrations.value)
      return null
    if (preferedAdministration.value) {
      return preferedAdministration.value
    }
    if (Object.keys(administrations.value).length > 1)
      return null
    return Object.values(administrations.value)[0]['@id']
  })

  function setPreferedAdministration(iri: string) {
    if (preferedAdministration.value === iri) {
      preferedAdministration.value = null
      return
    }
    preferedAdministration.value = iri
  }

  const removeAdministration = async (iri: string) => {
    if (!administrations.value)
      return
    if (iri in administrations.value) {
      delete administrations.value[iri]
    }
  }

  const addAdministration = (administration: AdministrationHydraCollectionitem) => {
    if (!administrations.value)
      return
    administrations.value[administration['@id']] = administration
  }
  return {
    reset,
    setPreferedAdministration,
    getPreferedAdministration,
    getAdministrationLabel,
    preferedAdministration,
    removeAdministration,
    getAdministrationOptions,
    administrationsOptionsIdBased,
    allowAdministrationsRead,
    getAdministration,
    addAdministration,
    administrations,
    setAdministrations,
    administrationsOptions,
    items,
    loading,
    initialLoading,
  }
})
