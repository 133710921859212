export enum EntityFields {
  String = 'String',
  Number = 'Number',
  Currency = 'Currency',
  Boolean = 'Boolean',
  Date = 'Date',
}

export enum AutomationEntities {
  Payment = 'payment',
  CaseFile = 'caseFile',
  // Add other event types here
}
