import type { AppCategoryEnum, AppCenterHydraItem, AppCenterSubscriptionHydraItem } from './application.interface.ts'
import { createInjectionState } from '@vueuse/shared'
import { sort } from 'fast-sort'
import { ref } from 'vue'
import AbortedError from '../../api/Error/AbortedError.ts'

import { useServerErrorHandler } from '../../utils'
import {
  AppSubscriptionAuthDocumentTypeEnum,
  AppSubscriptionDataTypeEnum,
  getRandomAppCategory,
} from './application.interface.ts'
import { ApplicationStatusEnum } from './application.state.ts'
import { useApps } from './useApps.ts'
import { useAppSubscriptions } from './useAppSubscriptions.ts'

const [useProvideApplicationStore, useApplicationStoreRaw]
    = createInjectionState(() => {
      const route = useRoute()
      const internal = computed<boolean>(() => route.meta.internal as boolean ?? false)
      const category = computed<AppCategoryEnum | null>(() => route.query.category ? Number.parseInt(route.query.category as string) as AppCategoryEnum : null)
      const abortController = ref(new AbortController())
      const search = ref('')
      const debouncedSearch = useDebounce(search, 250)
      const loading = ref(false)
      const currentLoadingPromise = ref<Promise<void> | null>(null)
      const { onError } = useServerErrorHandler()

      const isSearching = computed<boolean>(() => search.value !== '')

      const {
        appSubscriptionsList,
        getAppSubscriptions,
        ...appSubscriptions
      } = useAppSubscriptions({ category, debouncedSearch })
      const {
        applicationList,
        getApps,
        ...apps
      } = useApps()

      const getAppAndSubscriptions = async () => {
        console.log('getAppAndSubscriptions')
        if (loading.value)
          return
        try {
          loading.value = true
          await Promise.all([
            getAppSubscriptions({ signal: abortController.value.signal }),
            getApps({ signal: abortController.value.signal }),
          ])
        }
        catch (e) {
          if (e instanceof AbortedError) {
            return
          }
          onError(e)
        }
        finally {
          loading.value = false
        }
      }

      const computedAppSubscriptions = computed<AppCenterSubscriptionHydraItem[]>(() => {
        if (applicationList.value.length === 0 && loading.value) {
          return Array.from({ length: 4 }, (_, index) => {
            return {
              'id': index + 1,
              '@id': `/api/applications/${index + 1}`,
              '@context': '/api/contexts/AppHydraItem',
              '@type': 'AppSubscription',
              'app': {
                'id': index + 1,
                '@id': `/api/applications/${index + 1}`,
                'category': getRandomAppCategory(),
                'description': '',
                'appSubscriptionDataType': AppSubscriptionDataTypeEnum.ExactInternal,
                'appSubscriptionAuthDataType': AppSubscriptionAuthDocumentTypeEnum.OAuth,
                'name': '',
                '@context': '/api/contexts/AppHydraItem',
                '@type': 'App',
                'status': index < 2 ? ApplicationStatusEnum.INTERNAL : ApplicationStatusEnum.PUBLISHED,
              },
              'createdAt': new Date().toISOString(),
              'updatedAt': new Date().toISOString(),
              'loading': true,
            } satisfies AppCenterSubscriptionHydraItem
          })
        }
        return appSubscriptionsList.value.filter((sub) => {
          return internal.value ? sub.app.status === ApplicationStatusEnum.INTERNAL : true
        }).map((sub) => {
          return {
            ...sub,
            loading: false,
          }
        })
      })

      const computedApplications = computed<AppCenterHydraItem[]>(() => {
        if (applicationList.value.length === 0 && loading.value) {
          return Array.from({ length: 3 }, (_, index) => {
            return {
              'id': index + 1,
              '@id': `/api/applications/${index + 1}`,
              'name': '',
              'description': '',
              'category': getRandomAppCategory(),
              'appSubscriptionDataType': AppSubscriptionDataTypeEnum.ExactInternal,
              'appSubscriptionAuthDataType': AppSubscriptionAuthDocumentTypeEnum.OAuth,
              '@context': '/api/contexts/AppHydraItem',
              '@type': 'App',
              'status': ApplicationStatusEnum.INTERNAL,
              'hasSubscription': false,
              'loading': true,
            }
          })
        }
        return sort(applicationList.value.map((app) => {
          return {
            ...app,
            hasSubscription: appSubscriptionsList.value.some(
              sub => sub.app.id === app.id,
            ),
            loading: false,
          }
        }).filter((app) => {
          return (debouncedSearch.value ? app.name.toLowerCase().includes(debouncedSearch.value.toLowerCase()) : true)
            && (internal.value ? app.status === ApplicationStatusEnum.INTERNAL : true)
            && (category.value !== null ? app.category === category.value : true)
        })).desc(['hasSubscription'])
      })

      const cancelGetAppAndSubscriptions = () => {
        abortController.value.abort()
      }

      onMounted(() => {
        currentLoadingPromise.value = getAppAndSubscriptions()
        currentLoadingPromise.value.finally(() => {
          currentLoadingPromise.value = null
        })
      })
      onUnmounted(() => {
        cancelGetAppAndSubscriptions()
      })

      const isInitialLoading = computed(() => {
        return appSubscriptionsList.value.length === 0 && loading.value
      })

      return {
        getAppAndSubscriptions,
        search,
        isInitialLoading,
        debouncedSearch,
        ...appSubscriptions,
        currentLoadingPromise,
        ...apps,
        isSearching,
        loading,
        cancelGetAppAndSubscriptions,
        category,
        computedApplications,
        computedAppSubscriptions,
      }
    })
export { useProvideApplicationStore }

// If you want to hide `useApplicationStore` and wrap it in default value logic or throw error logic, please don't export `useApplicationStore`

export function useApplicationStore() {
  const applicationStore = useApplicationStoreRaw()
  if (applicationStore == null) {
    throw new Error(
      'Please call `useProvideApplicationStore` on the appropriate parent component',
    )
  }
  return applicationStore
}
