import type { UserRole } from 'lib'
import app from '@core/configApp.ts'
import * as Sentry from '@sentry/vue'
import { routes } from '@src/routes/routes.ts'
import { useAuthStore } from 'lib'

import { usePermissions } from 'lib/src/composables/features/permissions/usePermissions.ts'
import { createRouter, createWebHistory } from 'vue-router'

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach((to, _, next) => {
  const { isAuthenticated, hasRole, returnUrl, isRemembered } = useAuthStore()
  const isRequiredAuth = to.matched.some(
    record => record.meta.isRequiredAuth,
  )

  if (isRequiredAuth && !isAuthenticated.value && !isRemembered.value && to.name !== 'debug') {
    returnUrl.value = to.fullPath
    return next({
      name: 'login',
    })
  }
  else {
    if (
      (to.meta.permissions && to.meta.permissions.length > 0)
      || (to.meta.permissionResources && to.meta.permissionResources.length > 0)
      || (to.meta.permissionsFuzzy && to.meta.permissionsFuzzy.length > 0)
    ) {
      let allowed = false
      const { hasPermission, hasAnySourcePermission } = usePermissions()

      if (to.meta.permissions) {
        allowed = to.meta.permissions.some(permission => hasPermission(permission))
      }

      if (!allowed && to.meta.permissionsFuzzy) {
        allowed = to.meta.permissionsFuzzy.some(permission => hasPermission(permission, { fuzzy: true }))
      }

      if (!allowed && to.meta.permissionResources) {
        allowed = to.meta.permissionResources.some(resource => hasAnySourcePermission(resource))
      }

      if (!allowed) {
        console.log('Not allowed because of permissions')
        return next({ name: 'access_denied' })
      }
    }

    if (to.meta.roles?.length) {
      const allowed = to.meta.roles.every(role => hasRole(role as UserRole))

      if (!allowed) {
        console.log('Not allowed because of roles')
        return next({ name: 'access_denied' })
      }
    }
    console.log('rerender?')
    return next()
  }
})

app.use(router)
if (import.meta.env.PROD && import.meta.env.VITE_TESTING !== 'true') {
  Sentry.init({
    app,
    dsn: 'https://e6c271112b764fd79b7e1b74c7c01b06@sentry.vitrion.dev/42',
    environment: import.meta.env.MODE === 'production' ? 'production' : 'development',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
