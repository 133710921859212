<script setup lang="ts">
import { useTheme } from '@features/theme/useTheme.ts'
import { Debug, loadingDayjs, useAuthListener, useAuthStore } from 'lib'
import { setYupLocales } from 'lib/src/composables/core/plugin-yup.ts'
import { Toaster } from 'vue-sonner'

const { userPreferences } = useAuthStore()
const { locale } = useI18n()
const { t } = useI18n()
watch(() => userPreferences.value?.locale, (loc) => {
  locale.value = loc.toLowerCase()
  setYupLocales(t)
}, { immediate: true })
const { loading } = useTheme()
useAuthListener()
if (import.meta.env.VITE_TESTING === 'true') {
  onMounted(async () => {
    window.login = useAuthStore().fakeLogin
  })
}
const mode = useColorMode()

const isLoading = computed(() => loading.value || loadingDayjs.value)
</script>

<template>
  <Toaster :theme="mode === 'dark' ? 'dark' : 'light'" :visible-toasts="9" rich-colors position="bottom-right" :expand="true" />
  <Debug />
  <Transition mode="out-in" name="fade">
    <div
      v-if="isLoading"
      class="absolute surface-50 top-0 bottom-0 right-0 left-0 z-1 flex justify-content-center align-items-center "
    >
      <ProgressSpinner
        style="width: 50px; height: 50px"
        stroke-width="4"
        fill="var(--surface-50)"
        animation-duration=".5s"
        aria-label="Custom ProgressSpinner"
      />
    </div>
  </Transition>
  <template v-if="!isLoading">
    <Toast />
    <ConfirmDialog />
    <RouterView v-slot="{ Component }">
      <component :is="Component" />
    </RouterView>
  </template>
</template>
