import type { HydraContext, HydraTimeBased } from '../../../common'
import type { AddressWriteInterface } from '../address'
import type { OrganizationInterface } from '../organization'
import type { ProfileInterface } from '../profile'

export enum legalFormTypeEnum {
  OTHER = 'other',
  BV = 'bv',
  NV = 'nv',
  VOF = 'vof',
  CV = 'cv',
  FOUNDING = 'founding',
  ASSOCIATION = 'association',
  PROPRIETORSHIP = 'proprietorship',
  PARTNERSHIP = 'partnership',
}

export enum BankAccountTypeEnum {
  BANK = 'bank',
  GIRO = 'giro',
}

export enum PayoutTimeEnum {
  AT_CLOSURE = 'at_closure',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export enum CheckoutModelEnum {
  DEFAULT = 'default',
  TABLE = 'table',
}

export enum BillingEnum {
  END_DECLARATION = 'end_declaration',
}

export function billingOptions(t: (v: string) => string): {
  label: string
  value: BillingEnum
}[] {
  return [
    {
      label: t('clients.billing.options.end_declaration'),
      value: BillingEnum.END_DECLARATION,
    },
  ]
}

export function checkoutModelOptions(t: (v: string) => string): {
  label: string
  value: CheckoutModelEnum
}[] {
  return [
    {
      label: t('clients.checkoutModel.options.default'),
      value: CheckoutModelEnum.DEFAULT,
    },
    {
      label: t('clients.checkoutModel.options.table'),
      value: CheckoutModelEnum.TABLE,
    },
  ]
}

export function payoutTimeOptions(t: (v: string) => string): {
  label: string
  value: PayoutTimeEnum
}[] {
  return [
    {
      label: t('clients.payoutTime.options.at_closure'),
      value: PayoutTimeEnum.AT_CLOSURE,
    },
    {
      label: t('clients.payoutTime.options.weekly'),
      value: PayoutTimeEnum.WEEKLY,
    },
    {
      label: t('clients.payoutTime.options.monthly'),
      value: PayoutTimeEnum.MONTHLY,
    },
  ]
}

export function BankAccountType(t: (v: string) => string): {
  label: string
  value: BankAccountTypeEnum
}[] {
  return [
    {
      label: t('clients.bankAccount.account_type.options.bank'),
      value: BankAccountTypeEnum.BANK,
    },
    {
      label: t('clients.bankAccount.account_type.options.giro'),
      value: BankAccountTypeEnum.GIRO,
    },
  ]
}

export function legalFormOptions(t: (v: string) => string): {
  label: string
  value: legalFormTypeEnum
}[] {
  return [
    {
      label: t('clients.legal_form.options.other'),
      value: legalFormTypeEnum.OTHER,
    },
    {
      label: t('clients.legal_form.options.bv'),
      value: legalFormTypeEnum.BV,
    },
    {
      label: t('clients.legal_form.options.nv'),
      value: legalFormTypeEnum.NV,
    },
    {
      label: t('clients.legal_form.options.vof'),
      value: legalFormTypeEnum.VOF,
    },
    {
      label: t('clients.legal_form.options.cv'),
      value: legalFormTypeEnum.CV,
    },
    {
      label: t('clients.legal_form.options.founding'),
      value: legalFormTypeEnum.FOUNDING,
    },
    {
      label: t('clients.legal_form.options.association'),
      value: legalFormTypeEnum.ASSOCIATION,
    },
    {
      label: t('clients.legal_form.options.proprietorship'),
      value: legalFormTypeEnum.PROPRIETORSHIP,
    },
    {
      label: t('clients.legal_form.options.partnership'),
      value: legalFormTypeEnum.PARTNERSHIP,
    },
  ]
}

interface Feature extends HydraContext {
}

interface PaymentData extends HydraContext {
  bic: string
  iban: string
}

export interface Address extends HydraContext {
  addressLine1: string
  addressLine2?: string
  locality: string
  region?: string
  country: string
  zipCode: string
}

export interface ClientHydraItem extends OrganizationInterface, ProfileInterface, HydraContext, HydraTimeBased {
  '@type': 'Client'
  'id': number
  'address': Address
  'thirdPartyReference': string
  'thirdPartyName': string
  'effectiveFeatures'?: Feature[]
  'accountingPackage': string
  'paymentData'?: PaymentData
  'paymentTerm': number
  'payoutTime': PayoutTimeEnum
  'checkoutModel': CheckoutModelEnum
  'settle': boolean
  'payInterest': boolean
  'weeklyPayout': boolean
  'setOffPayoutAgainstCosts': boolean
  'collectionPercentage': number
  'interestPercentage': number
  'minCollectionCost': number
  'withHolding': number
  'provision': number
  'minProvision': number
  'notifications': boolean
  'billing': BillingEnum
}

export type ClientHydraCollectionItem = Pick<
  ClientHydraItem,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'thirdPartyReference'
  | 'thirdPartyName'
  | 'effectiveFeatures'
  | 'emailAddress'
  | 'mobilePhoneNumber'
  | 'locale'
  | 'number'
  | 'address'
  | 'name'
  | 'legalForm'
> & HydraContext

export interface ClientsStats {
  bookmarked: number
}

export interface CaseFileClient extends HydraContext {
  '@type': 'Client'
  'id': number
  'name': string
}

export interface ClientWriteWithoutAddress extends OrganizationInterface, Omit<ProfileInterface, 'picture'> {
  accountingPackage: string
  settle: boolean
  weeklyPayout: boolean
  payInterest: boolean
  address: string
}

export interface ClientWriteAddress {
  address: AddressWriteInterface
}

export interface ClientWriteIndividual {
  client: string
  individual: string
}
