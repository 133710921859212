import type { TenantHydraCollectionItem } from './tenants.interface.ts'
import { StorageSerializers, useStorage } from '@vueuse/core'
import { PermissionAttributes, usePermissions } from '../..'
import { getAll } from '../../api'

export const useTenantsStore = createGlobalState(() => {
  const tenants = useStorage<Record<string, TenantHydraCollectionItem> | null>('tenants', null, undefined, { serializer: StorageSerializers.object })

  const items = ref<TenantHydraCollectionItem[]>([])

  const loading = ref(false)
  const initialLoading = ref(false)

  function reset() {
    loading.value = false
    initialLoading.value = false
    items.value = []
    tenants.value = null
  }

  const { hasPermission } = usePermissions()

  const allowReadTenants = computed(() =>
    hasPermission(
      PermissionAttributes.CONTEXT.TENANT.OPERATION.TENANT.READ_SINGLE,
      {
        fuzzy: true,
      },
    ),
  )

  async function setTenants(): Promise<void> {
    if (!allowReadTenants.value) {
      tenants.value = null
      return
    }
    await getAll<TenantHydraCollectionItem>({ url: '/api/tenants' }).then((data: TenantHydraCollectionItem[]) => {
      tenants.value = data.reduce((acc, item) => {
        acc[item['@id']] = item
        return acc
      }, {} as Record<string, TenantHydraCollectionItem>)
      return data
    })
  }

  const tenantsOptions = computed(() => {
    return tenants.value
      ? Object.values(tenants.value).map(status => ({
          label: status.name,
          value: status['@id'],
        }))
      : []
  })
  const firstTenant = computed<TenantHydraCollectionItem | null>(() => {
    return tenants.value ? Object.values(tenants.value)?.[0] ?? null : null
  })

  return {
    tenantsOptions,
    firstTenant,
    allowReadTenants,
    setTenants,
    reset,
    items,
    tenants,
    loading,
    initialLoading,
  }
})
