import type { CaseFileImportItemSchema, CaseFileImportSchema, CsvRowItemSchema, CsvRowSchemaStringValues } from './useCaseFileImport.schema.ts'
import { createInjectionState } from '@vueuse/core'
import { parsePhoneNumberWithError } from 'libphonenumber-js'
import Papa from 'papaparse'
import { useI18n } from 'vue-i18n'
import { ValidationError } from 'yup'
import {

  caseFileImportSchema,

} from './useCaseFileImport.schema.ts'

const [useProvideCaseFileImport, useCaseFileImportRaw] = createInjectionState(() => {
  const { t } = useI18n()
  const errors = ref<{
    row: number
    errors: {
      path: string
      message: string
    }[]
  }[]>([])

  const currentUnix = ref<number | null>(null)

  const hasStartedBulkCreate = ref<boolean>(false)

  const { value: data, errorMessage, errors: dataErrors } = useField<CsvRowItemSchema[]>('data')

  async function convertCsvToJson(file: File): Promise<CsvRowItemSchema[]> {
    errors.value = []
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header: string) => header.trim(),
        complete: async (results) => {
          console.log('[useCaseFileImport] - CSV parsed successfully')
          try {
            console.log('[useCaseFileImport] - results', results.data)
            const validatedData = await validateCsvData(results.data as CsvRowSchemaStringValues)
            console.log('[useCaseFileImport] - validatedData', validatedData)
            const transformedData = transformCsvData(validatedData)
            console.log('[useCaseFileImport] - transformedData', transformedData)
            resolve(transformedData)
          }
          catch (error) {
            reject(error)
          }
        },
        error: (error) => {
          console.log('[useCaseFileImport] - Error parsing file:', error)
          reject(error)
        },
      })
    })
  }

  async function validateCsvData(data: CsvRowSchemaStringValues): Promise<CaseFileImportItemSchema[]> {
    const validatedData: CaseFileImportItemSchema[] = []
    const schema = caseFileImportSchema(t)

    try {
      const validatedResult = await schema.validate({ data }, {
        abortEarly: false,
        stripUnknown: true,
      })
      console.log('[useCaseFileImport] - validatedResult', validatedResult)
      validatedData.push(...validatedResult.data)
    }
    catch (error) {
      if (error instanceof ValidationError) {
        error.inner.forEach((err) => {
          const match = err.path?.match(/data\[(\d+)\]/)
          if (match) {
            const rowIndex = Number.parseInt(match[1], 10)
            if (!errors.value[rowIndex]) {
              errors.value[rowIndex] = { row: rowIndex + 1, errors: [] }
            }
            const path = err.path?.replace(`data[${rowIndex}].`, '').replace(' ', '')
            errors.value[rowIndex].errors.push({
              path: t(`common.import_casefiles.columns.${path}`),
              message: err.message,
            })
          }
        })
      }
    }

    return validatedData
  }

  function transformCsvData(data: CaseFileImportSchema['data']): CsvRowItemSchema[] {
    if (currentUnix.value === null) {
      throw new Error('Current unix is null')
    }

    let id = 1
    const groupedData = data.reduce((acc, row, index) => {
      if (!acc[row.debiteurnr]) {
        const { factuurnr, factuurdatum, vervaldatum, bedrag, ...rest } = row
        let mobielCountry = 'NL'
        if (rest.mobiel) {
          try {
            const phone = parsePhoneNumberWithError(rest.mobiel)
            mobielCountry = phone.country as string
          }
          catch (e) {
            console.log(e)
          }
        }
        let telefoonCountry = 'NL'
        if (rest.telefoon) {
          try {
            const phone = parsePhoneNumberWithError(rest.telefoon)
            telefoonCountry = phone.country as string
          }
          catch (e) {
            console.log(e)
          }
        }
        acc[row.debiteurnr] = {
          ...rest,
          rowIdentifier: index + currentUnix.value! as number,
          id,
          showDialog: false,
          mobielCountry,
          telefoonCountry,
          invoices: [],
        }
        id++
      }
      acc[row.debiteurnr].invoices.push({
        factuurnr: row.factuurnr,
        factuurdatum: row.factuurdatum ?? new Date(),
        vervaldatum: row.vervaldatum ?? new Date(),
        bedrag: row.bedrag ?? 0,
      })
      return acc
    }, {} as Record<string, CsvRowItemSchema>)

    return Object.values(groupedData)
  }

  const importData = async (file: File): Promise<void> => {
    data.value = []
    const convertedData = await convertCsvToJson(file)
    data.value = convertedData
  }

  return { importData, data, errors, errorMessage, dataErrors, hasStartedBulkCreate, currentUnix }
})

export { useProvideCaseFileImport }

export function useCaseFileImport() {
  const caseFileImportState = useCaseFileImportRaw()
  if (caseFileImportState == null) {
    throw new Error(
      'Please call `useProvideCaseFileImport` on the appropriate parent component',
    )
  }
  return caseFileImportState
}
