import { InstanceType, useConfigStore, useInstanceStore } from 'lib'

const config = useConfigStore()

config.setInitialConfig({
  apiUrl: import.meta.env.VITE_API_URL,
  loginUrl: import.meta.env.VITE_LOGIN_URL,
  loginRedirect: import.meta.env.VITE_LOGIN_REDIRECT_URL,
  loginClientId: import.meta.env.VITE_LOGIN_CLIENT_ID,
  loginAuthorizeUrl: import.meta.env.VITE_LOGIN_AUTHORIZE_URL,
  loginTokenUrl: import.meta.env.VITE_LOGIN_TOKEN_URL,
  mercureUrl: import.meta.env.VITE_MERCURE_URL,
  userContentUrl: import.meta.env.VITE_USERCONTENT,
})
const instanceStore = useInstanceStore()

instanceStore.setInstanceType(InstanceType.CMS)

if (import.meta.env.VITE_TESTING === 'true')
  instanceStore.setIsTesting(true)
