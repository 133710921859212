import type {
  CustomFieldMappingHydraCollectionItem,
  CustomFieldMappingHydraItem,
} from './custom_field_mappings.interface.ts'
import { StorageSerializers, useStorage } from '@vueuse/core'
import { getAll } from '../../api'
import { PermissionAttributes, usePermissions } from '../permissions'
import { CustomFieldEntityType } from './custom_field_mappings.interface.ts'

export const useCustomFieldMappingsStore = createGlobalState(() => {
  const customFieldMappings = useStorage<Record<string, CustomFieldMappingHydraCollectionItem> | null>('custom_field_mappings', null, undefined, { serializer: StorageSerializers.object })

  const customFieldMappingsList = computed<CustomFieldMappingHydraCollectionItem[]>(() => {
    return Object.values(customFieldMappings.value || {})
  })

  const items = ref<CustomFieldMappingHydraCollectionItem[]>([])

  const loading = ref(false)
  const initialLoading = ref(false)

  function reset() {
    loading.value = false
    initialLoading.value = false
    items.value = []
    customFieldMappings.value = null
  }

  const namesPerEntityTypes = computed(() => {
    return Object.values(CustomFieldEntityType).filter(v => typeof v === 'number').reduce((acc, entityType) => {
      acc[`${entityType}`] = items.value.filter(item => item.entityType === entityType).map(item => item.name)
      return acc
    }, {} as Record<string, string[]>)
  })

  const getMappingPerEntityType = (entityType: CustomFieldEntityType): CustomFieldMappingHydraCollectionItem[] => {
    return customFieldMappingsList.value.filter(item => item.entityType === entityType)
  }

  const { hasPermission } = usePermissions()

  const allowReadCustomFieldMappings = computed(() =>
    hasPermission(
      PermissionAttributes.GLOBAL.OPERATION.CUSTOM_FIELD_MAPPING
        .READ_COLLECTION,
    ),
  )

  async function setCustomFieldMappings(): Promise<void> {
    if (!allowReadCustomFieldMappings.value) {
      customFieldMappings.value = null
      return
    }
    await getAll<CustomFieldMappingHydraCollectionItem>({ url: '/api/custom_field_mappings' }).then((data: CustomFieldMappingHydraCollectionItem[]) => {
      customFieldMappings.value = data.reduce((acc, item) => {
        acc[item['@id']] = item
        return acc
      }, {} as Record<string, CustomFieldMappingHydraCollectionItem>)
      return data
    })
  }

  const customFieldMappingsOptions = computed(() => {
    return customFieldMappings.value
      ? Object.values(customFieldMappings.value).map(status => ({
          label: status.name,
          value: status.name,
        }))
      : []
  })

  function upsertCustomFieldMapping(item: CustomFieldMappingHydraItem) {
    if (!customFieldMappings.value) {
      customFieldMappings.value = {}
    }

    customFieldMappings.value[item.id] = item
  }

  function removeCustomFieldMapping(item: CustomFieldMappingHydraCollectionItem) {
    if (!customFieldMappings.value) {
      return
    }
    delete customFieldMappings.value[item.id]
  }

  return {
    upsertCustomFieldMapping,
    removeCustomFieldMapping,
    customFieldMappingsOptions,
    getMappingPerEntityType,
    customFieldMappingsList,
    allowReadCustomFieldMappings,
    setCustomFieldMappings,
    namesPerEntityTypes,
    reset,
    items,
    customFieldMappings,
    loading,
    initialLoading,
  }
})
