import type { initialFieldTypes } from '@features/export/export.interface.ts'
import type { usePermissions } from 'lib/src/composables'
import type { InferType } from 'yup'
import {
  EntityTypeBoolean,
  EntityTypeDate,
  EntityTypeEnum,
  EntityTypeLocale,
  EntityTypeNumber,
  EntityTypeRelation,
  EntityTypeString,
  EntityTypeTimezone,
} from '@features/export/export.interface.ts'
import {
  discountCalculationModeOptions,
  durationPatternDateOptions,
  ExportEntityEnum,
  ExportTypeEnum,
  legalFormOptions,
  useCaseFileStore,
  vatTypeOptions,
} from 'lib'
import { iriToId, PermissionAttributes } from 'lib/src/composables'
import { useAdministrationStore } from 'lib/src/composables/features/administrations/useAdministrationStore.ts'
import { interestCalculationModeOptions } from 'lib/src/composables/features/settings/interest_rate_settings.schema.ts'
import { boolean, number, object, string } from 'yup'

interface ExportEntityFieldsType {
  [ExportEntityEnum.CLIENT]: Record<string, any>
  [ExportEntityEnum.INVOICE]: Record<string, any>
  [ExportEntityEnum.ORGANIZATION]: Record<string, any>
  [ExportEntityEnum.INDIVIDUAL]: Record<string, any>
  [ExportEntityEnum.CASE_FILE]: Record<string, any>
  [ExportEntityEnum.SUBSCRIPTION]: Record<string, any>
}

export const ExportEntityFilters: ExportEntityFieldsType = {
  [ExportEntityEnum.CLIENT]: {
    id: EntityTypeNumber,
    name: EntityTypeString,
    tradeName: EntityTypeString,
    legalForm: EntityTypeEnum,
    chamberOfCommerceNumber: EntityTypeString,
    valueAddedTaxNumber: EntityTypeString,
    vatLiable: EntityTypeBoolean,
    emailAddress: EntityTypeString,
    phoneNumber: EntityTypeString,
    mobilePhoneNumber: EntityTypeString,
    locale: EntityTypeLocale,
    timezone: EntityTypeTimezone,
    createdAt: EntityTypeDate,
    updatedAt: EntityTypeDate,
  },
  [ExportEntityEnum.INVOICE]: {
    id: EntityTypeNumber,
    administration: EntityTypeEnum,
    number: EntityTypeString,
    dateAt: EntityTypeDate,
    dueDateAt: EntityTypeDate,
    pdfTemplate: EntityTypeRelation,
    emailTemplate: EntityTypeRelation,
    createdAt: EntityTypeDate,
    updatedAt: EntityTypeDate,
  },
  [ExportEntityEnum.ORGANIZATION]: {
    id: EntityTypeNumber,
    name: EntityTypeString,
    tradeName: EntityTypeString,
    legalForm: EntityTypeEnum,
    chamberOfCommerceNumber: EntityTypeString,
    valueAddedTaxNumber: EntityTypeString,
    vatLiable: EntityTypeBoolean,
    emailAddress: EntityTypeString,
    phoneNumber: EntityTypeString,
    mobilePhoneNumber: EntityTypeString,
    locale: EntityTypeLocale,
    timezone: EntityTypeTimezone,
    createdAt: EntityTypeDate,
    updatedAt: EntityTypeDate,
  },
  [ExportEntityEnum.INDIVIDUAL]: {
    id: EntityTypeNumber,
    birthDateAt: EntityTypeDate,
    emailAddress: EntityTypeString,
    phoneNumber: EntityTypeString,
    mobilePhoneNumber: EntityTypeString,
    locale: EntityTypeLocale,
    timezone: EntityTypeTimezone,
    createdAt: EntityTypeDate,
    updatedAt: EntityTypeDate,
  },
  [ExportEntityEnum.CASE_FILE]: {
    id: EntityTypeNumber,
    sort: EntityTypeEnum,
    confirmedAt: EntityTypeDate,
    closedAt: EntityTypeDate,
    administration: EntityTypeEnum,
    closeReason: EntityTypeRelation,
    collectionCostGroup: EntityTypeRelation,
    createdAt: EntityTypeDate,
    updatedAt: EntityTypeDate,
    status: EntityTypeEnum,
    creditor: EntityTypeRelation,
    debtor: EntityTypeRelation,
  },
  [ExportEntityEnum.SUBSCRIPTION]: {
    id: EntityTypeNumber,
    administration: EntityTypeEnum,
    number: EntityTypeString,
    startAt: EntityTypeDate,
    extensionAt: EntityTypeDate,
    endAt: EntityTypeDate,
    frequency: EntityTypeEnum,
    invoicePdfTemplate: EntityTypeRelation,
    invoiceEmailTemplate: EntityTypeRelation,
    createdAt: EntityTypeDate,
    updatedAt: EntityTypeDate,
  },
}

export interface relationOptionsInterface {
  endpoint: string | ((v: string | number) => string)
  dependsOn?:
    | {
      endpoint: string
      optionLabel: string
      optionValue: string
      searchField: string
      options: null
      label?: string
      hasPerms: boolean
    }
    | {
      options: any[]
      label?: string
      hasPerms: boolean
    }
  hasPerms: boolean
  optionLabel: string
  label?: string
  optionValue: string
  searchField: string
}

export type transformedRelationOptionsInterface =
  Pick<relationOptionsInterface, 'optionValue' | 'optionLabel' | 'searchField'>
  & {
    endpoint: string
  }

export function getRelationOptions(
  hasPermission: ReturnType<typeof usePermissions>['hasPermission'],
  _: ExportEntityEnum,
  key: string,
): relationOptionsInterface | null {
  if (key === 'pdfTemplate' || key === 'invoicePdfTemplate') {
    return {
      endpoint: '/api/pdf_templates',
      optionLabel: 'name',
      optionValue: 'id',
      searchField: 'name',
      hasPerms: hasPermission(
        PermissionAttributes.GLOBAL.OPERATION.PDF_TEMPLATE.READ_COLLECTION,
      ),
    }
  }
  if (key === 'emailTemplate' || key === 'invoiceEmailTemplate') {
    return {
      endpoint: '/api/email_templates',
      optionLabel: 'name',
      optionValue: 'id',
      searchField: 'name',
      hasPerms: hasPermission(
        PermissionAttributes.GLOBAL.OPERATION.EMAIL_TEMPLATE.READ_COLLECTION,
      ),
    }
  }
  if (key === 'closeReason') {
    return {
      endpoint: '/api/case_file_close_reasons',
      optionLabel: 'name',
      optionValue: 'id',
      searchField: 'name',
      hasPerms: hasPermission(
        PermissionAttributes.GLOBAL.OPERATION.CASE_FILE_CLOSE_REASON.READ_COLLECTION,
      ),
    }
  }
  if (key === 'collectionCostGroup') {
    return {
      endpoint: '/api/collection_cost_groups',
      optionLabel: 'name',
      optionValue: 'id',
      hasPerms: hasPermission(
        PermissionAttributes.GLOBAL.OPERATION.COLLECTION_COST_GROUP.READ_COLLECTION,
      ),
      searchField: 'name',
    }
  }
  if (key === 'generalLedger') {
    const { administrationsOptions } = useAdministrationStore()
    return {
      dependsOn: {
        options: administrationsOptions.value,
        label: 'administrations.label',
        hasPerms: hasPermission(
          PermissionAttributes.GLOBAL.OPERATION.ADMINISTRATION.READ_COLLECTION,
        ),
      },
      endpoint: (iri: string) => `/api/administrations/${iriToId(iri)}/general_ledgers`,
      optionLabel: 'name',
      optionValue: 'id',
      label: 'general_ledger.label',
      searchField: 'name',
      hasPerms: hasPermission(
        PermissionAttributes.GLOBAL.OPERATION.GENERAL_LEDGER.READ_COLLECTION_VIA_ADMINISTRATION,
      ),
    }
  }
  return null
}

export function getEnumOptions(
  hasPermission: ReturnType<typeof usePermissions>['hasPermission'],
  t: (v: string) => string,
  entity: ExportEntityEnum,
  key: string,
): any[] | boolean {
  if (!entity)
    return []
  if (key === 'interestRefreshPeriod' || key === 'refreshPeriod') {
    return durationPatternDateOptions(t)
  }
  if (key === 'interestCalculationMode') {
    return interestCalculationModeOptions(t)
  }

  if (key === 'vatType') {
    return vatTypeOptions(t)
  }
  if (key === 'discountMode') {
    return discountCalculationModeOptions(t)
  }

  if (key === 'administration' || key.includes('.administration')) {
    if (!hasPermission(
      PermissionAttributes.GLOBAL.OPERATION.ADMINISTRATION.READ_COLLECTION,
    )) {
      return false
    }
    const { administrationsOptions } = useAdministrationStore()
    return administrationsOptions.value
  }
  if (key === 'legalForm' || key.includes('.legalForm')) {
    return legalFormOptions(t)
  }
  if (entity === ExportEntityEnum.CASE_FILE) {
    if (key === 'status') {
      if (!hasPermission(
        PermissionAttributes.GLOBAL.OPERATION.CASE_FILE_STATUS.READ_COLLECTION,
      )) {
        return false
      }
      const { rawStatussesOptions } = useCaseFileStore()
      return rawStatussesOptions.value
    }
  }
  return []
}

export function getInitialFields(
  t: (v: string) => string,
  entity: ExportEntityEnum,
): initialFieldTypes {
  console.log('[ExportFields] getInitialFields', entity)
  const profileFields = (path?: string) => {
    const prefix = path ? (path.endsWith('.') ? path : `${path}.`) : ''

    return [
      {
        key: `${prefix}emailAddress`,
        label: t(`profile.emailAddress.label`),
      },
      {
        key: `${prefix}phoneNumber`,
        label: t(`profile.phoneNumber.label`),
      },
      {
        key: `${prefix}mobilePhoneNumber`,
        label: t(`profile.mobilePhoneNumber.label`),
      },
      {
        key: `${prefix}locale`,
        label: t(`profile.locale.label`),
      },
      {
        key: `${prefix}timezone`,
        label: t(`profile.timezone.label`),
      },
    ]
  }
  const organizationFields = (path?: string) => {
    const prefix = path ? (path.endsWith('.') ? path : `${path}.`) : ''

    return [
      {
        key: `${prefix}name`,
        label: t(`organization.name.label`),
      },
      {
        key: `${prefix}tradeName`,
        label: t(`organization.tradeName.label`),
      },
      {
        key: `${prefix}legalForm`,
        label: t(`organization.legalForm.label`),
      },
      {
        key: `${prefix}chamberOfCommerceNumber`,
        label: t(`organization.chamberOfCommerceNumber.label`),
      },
      {
        key: `${prefix}valueAddedTaxNumber`,
        label: t(`organization.valueAddedTaxNumber.label`),
      },
      {
        key: `${prefix}vatLiable`,
        label: t(`organization.vatLiable.label`),
      },
    ]
  }

  const addressInternalFields = (path?: string) => {
    const prefix = path ? (path.endsWith('.') ? path : `${path}.`) : ''

    return [
      {
        key: `${prefix}addressLine1`,
        label: t(`address.addressLine1.label`),
      },
      {
        key: `${prefix}locality`,
        label: t(`address.locality.label`),
      },
      {
        key: `${prefix}country`,
        label: t(`address.country.label`),
      },
      {
        key: `${prefix}zipCode`,
        label: t(`address.zipCode.label`),
      },
    ]
  }
  const addressFields = (path?: string) => {
    const prefix = path ? (path.endsWith('.') ? path : `${path}.`) : ''

    return [
      {
        key: `${prefix}address`,
        label: t(`address.label`),
        children: addressInternalFields(`${prefix}address`),
      },
    ]
  }
  const hydraDatesFields = (path?: string) => {
    const prefix = path ? (path.endsWith('.') ? path : `${path}.`) : ''

    return [
      {
        key: `${prefix}createdAt`,
        label: t(`common.createdAt.label`),
      },
      {
        key: `${prefix}updatedAt`,
        label: t(`common.updatedAt.label`),
      },
    ]
  }
  const hydraBaseFields = (path?: string) => {
    const prefix = path ? (path.endsWith('.') ? path : `${path}.`) : ''

    return [
      {
        key: `${prefix}id`,
        label: t(`common.id.label`),
      },
    ]
  }
  const clientFields = (path?: string) => [
    ...hydraBaseFields(path),
    ...organizationFields(path),
    ...profileFields(path),
    ...addressFields(path),
    ...hydraDatesFields(path),
  ]
  switch (entity) {
    case ExportEntityEnum.CLIENT:
      console.log('[ExportFields] CLIENT')
      return clientFields()
    case ExportEntityEnum.INVOICE:
      console.log('[ExportFields] INVOICE')
      return [
        ...hydraBaseFields(),
        {
          key: 'administration',
          label: t(`accounting.administration.label`),
        },
        {
          key: 'number',
          label: t(`accounting.number.label`),
        },
        {
          key: 'dateAt',
          label: t(`accounting.dateAt.label`),
        },
        {
          key: 'dueDateAt',
          label: t(`accounting.dueDateAt.label`),
        },
        {
          key: 'pdfTemplate',
          label: t(`accounting.pdfTemplate.label`),
        },
        {
          key: 'emailTemplate',
          label: t(`accounting.emailTemplate.label`),
        },
        {
          key: 'client',
          label: t(`accounting.client.label`),
        },
        ...hydraDatesFields(),
      ]
    case ExportEntityEnum.ORGANIZATION:
      console.log('[ExportFields] ORGANIZATION')
      return [
        ...hydraBaseFields(),
        ...organizationFields(),
        ...profileFields(),
        ...addressFields(),
        ...hydraDatesFields(),
      ]
    case ExportEntityEnum.INDIVIDUAL:
      console.log('[ExportFields] INDIVIDUAL')
      return [
        ...hydraBaseFields(),
        {
          key: 'birthDateAt',
          label: t(`individual.birthDateAt.label`),
        },
        ...profileFields(),
        ...addressFields(),
        ...hydraDatesFields(),
      ]

    case ExportEntityEnum.CASE_FILE:
      console.log('[ExportFields] CASE_FILE')
      return [
        ...hydraBaseFields(),
        {
          key: 'sort',
          label: t(`case_file.sort.label`),
        },
        {
          key: 'confirmedAt',
          label: t(`case_file.confirmedAt.label`),
        },
        {
          key: 'closedAt',
          label: t(`case_file.closedAt.label`),
        },
        {
          key: 'administration',
          label: t(`accounting.administration.label`),
        },
        {
          key: 'creditor',
          label: t(`case_file.client.label`),
          children: clientFields('creditor.'),
        },
        {
          key: 'debtor',
          label: t(`case_file.debtor.label`),
          children: profileFields('debtor.'),
        },
        {
          key: 'closeReason',
          label: t(`case_file.closeReason.label`),
        },
        {
          key: 'collectionCostGroup',
          label: t(`case_file.collection_cost_group.label`),
        },
        {
          key: 'status',
          label: t(`case_file.status.label`),
        },
        ...hydraDatesFields(),
      ]
    case ExportEntityEnum.SUBSCRIPTION:
      console.log('[ExportFields] SUBSCRIPTION')
      return [
        ...hydraBaseFields(),
        {
          key: 'administration',
          label: t(`accounting.administration.label`),
        },
        {
          key: 'number',
          label: t(`accounting.number.label`),
        },
        {
          key: 'startAt',
          label: t(`accounting.startAt.label`),
        },
        {
          key: 'extensionAt',
          label: t(`subscriptions.extensionAt.label`),
        },
        {
          key: 'endAt',
          label: t(`accounting.endAt.label`),
        },
        {
          key: 'frequency',
          label: t(`accounting.frequency.label`),
        },
        {
          key: 'invoicePdfTemplate',
          label: t(`accounting.pdfTemplate.label`),
        },
        {
          key: 'invoiceEmailTemplate',
          label: t(`accounting.emailTemplate.label`),
        },
        {
          key: 'client',
          label: t(`accounting.client.label`),
        },
        ...hydraDatesFields(),
      ]
  }
}

export function exportTemplateSchema() {
  return object({
    name: string().required(),
    type: number().oneOf(Object.values(ExportTypeEnum).filter(v => typeof v === 'number')).required(),
    public: boolean().required().default(false),
  })
}

export type ExportTemplateSchema = InferType<ReturnType<typeof exportTemplateSchema>>
