import type { CaseFileListHydraCollectionitem } from '../case_file_lists'
import type { CaseFileHydraCollectionItem, CaseFileSortEnum, CaseFilesStats } from './case_file.interface.ts'
import type { CaseFileStatusHydraCollectionItem } from './case_file_statuses'
import { StorageSerializers } from '@vueuse/core'
import { ref } from 'vue'
import { getAll } from '../../api'
import { PermissionAttributes, usePermissions } from '../permissions'
import { CaseFileCategoryEnum } from './case_file_statuses'

export const useCaseFileStore = createGlobalState(() => {
  const items = ref<CaseFileHydraCollectionItem[]>([])
  const statuses = useStorage<Record<string, CaseFileStatusHydraCollectionItem> | null>('case-file-statuses', null, undefined, { serializer: StorageSerializers.object })
  const loading = ref(false)
  const initialLoading = ref(false)
  const stats = ref<CaseFilesStats | null>(null)
  const statsLoading = ref(false)
  const lists = ref<CaseFileListHydraCollectionitem[]>([])

  const loadingSidebar = ref(false)
  const hasNextPageSidebar = ref(true)
  const currentPageSidebar = ref(1)
  const { hasPermission } = usePermissions()

  function reset() {
    loading.value = false
    initialLoading.value = false
    statsLoading.value = false
    items.value = []
    stats.value = null
  }

  const allowReadCaseFileStatusses = computed(() =>
    hasPermission(
      PermissionAttributes.GLOBAL.OPERATION.CASE_FILE_STATUS.READ_COLLECTION,
    ),
  )

  async function setStatusses(): Promise<void> {
    if (!allowReadCaseFileStatusses.value) {
      statuses.value = null
      return
    }
    await getAll<CaseFileStatusHydraCollectionItem>({ url: '/api/case_file_statuses' }).then((data: CaseFileStatusHydraCollectionItem[]) => {
      statuses.value = data.reduce((acc, item) => {
        acc[item['@id']] = item
        return acc
      }, {} as Record<string, CaseFileStatusHydraCollectionItem>)
      return data
    })
  }

  const rawStatussesOptions = computed(() => {
    return statuses.value
      ? Object.values(statuses.value).map(status => ({
          label: status.name,
          value: status['@id'],
          sort: status.sort,
          category: status.category,
        }))
      : []
  })

  function getCaseFileStatusStatusSeverity(status?: Pick<CaseFileStatusHydraCollectionItem, 'category' | 'sort'>) {
    if (!status) {
      return 'info'
    }
    switch (status.category) {
      case CaseFileCategoryEnum.Open:
        return 'success'
      case CaseFileCategoryEnum.OnHold:
        return 'info'
      case CaseFileCategoryEnum.Closed:
        return 'danger'
    }
  }

  const statusesOptions = (sortId?: CaseFileSortEnum) => {
    if (!sortId)
      return []
    /* Only get statuses by current selected sort */
    return rawStatussesOptions.value.filter(status => status.sort === sortId)
  }

  const statusesOptionsIdBased = computed(() => {
    return statuses.value
      ? Object.values(statuses.value).map(status => ({
          label: status.name,
          value: status.id,
        }))
      : []
  })

  return {
    stats,
    loadingSidebar,
    getCaseFileStatusStatusSeverity,
    lists,
    hasNextPageSidebar,
    currentPageSidebar,
    statusesOptions,
    statuses,
    statusesOptionsIdBased,
    allowReadCaseFileStatusses,
    reset,
    rawStatussesOptions,
    statsLoading,
    items,
    loading,
    setStatusses,
    initialLoading,
  }
})
