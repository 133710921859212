import type { Ref } from 'vue'
import type { HydraContext, InternalHydraItem } from '../../common'
import type { TableLoadOptions } from '../table'
import { useConfigStore } from '../config'
import { useMercure } from './useMercure'

export function useMercureItems<T extends HydraContext | InternalHydraItem, C extends HydraContext | InternalHydraItem = T>({
  topic,
  items,
  loadData,
  transform,
  onAddOrUpdate,
  onDelete,
}: {
  items: Ref<C[] | null>
  topic: string
  loadData?: (options?: TableLoadOptions) => Promise<void>
  transform?: (item: T) => C
  onAddOrUpdate?: (item: T) => void
  onDelete?: (item: T) => void
}) {
  const config = useConfigStore()
  useMercure<T>({
    hubUrlGetter: () => config.config.value?.mercureUrl || '',
    topic,
    onAddOrUpdate: async (item: T) => {
      console.log('onAddOrUpdate useMercureItems', item)
      onAddOrUpdate?.(item)
      if (!items.value)
        return

      const transformedItem = transform ? transform(item) : (item as unknown as C)

      if (items.value.find(item => item['@id'] === transformedItem['@id'])) {
        console.log('About to update:', transformedItem['@id'])
        items.value = items.value.map(item => item['@id'] === transformedItem['@id'] ? transformedItem : item)
      }
      else {
        loadData?.({
          refreshLastPage: true,
        }).then()
      }
    },
    onDelete: async (i) => {
      onDelete?.(i)

      if (!items.value)
        return

      items.value = items.value.filter(item => item['@id'] !== i['@id'])
    },
  })
}
