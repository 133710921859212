import type { InferType, Schema } from 'yup'
import type { HydraContext, HydraTimeBased } from '../../../common'
import type { CustomFieldValueValueSchemaValidate } from '../custom_field_values/custom_field_values.schema'
import type { ProfileTypeEnum } from '../profile'
import type { UserSnapshotHydraItem } from '../user'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { array, boolean, date, mixed, number, object, string } from 'yup'
import { createFileValidator, emailRegex, validImageExtensions } from '../../utils'
import { customFieldValuesSchema } from '../custom_field_values/custom_field_values.schema'
import { DebtorType } from '../debtor'
import { profileRawSchema } from '../profile'

export enum CaseFileSortEnum {
  CREDIT_MANAGEMENT = 1,
  COLLECTION = 2,
  LEGAL = 3,
}

export interface CaseFileHydraItem extends HydraContext, HydraTimeBased {
  '@type': 'CaseFile'
  'sort': CaseFileSortEnum
  'closedAt'?: string
  'debtor': string
  'creditor': string
  'createdBy': UserSnapshotHydraItem
  'caseFileLists': string[]
  'executor'?: string | null
  'id': number
  'debtorName': string
  'creditorName': string
  'type': ProfileTypeEnum
  'collectionCostGroupName': string
  'outstandingAmount'?: number
  'paidAmount'?: number
  'reservedPaidAmount'?: number
  // Can be undefined bcs of perms
  'confirmedAt'?: string
  'administration'?: string // iri reference
  'closeReason'?: string // iri reference
  'status'?: string // IRI For casefilestatus
  'collectionCostGroup'?: string // iri reference
  // MISSING?
  'number_of_third_parties': number
  'principal': number
  'totalTimePerStatus': {
    '@type': 'CaseFileStatusTimeTrackSummary'
    '@id': string
    'status': number
    'duration': number
  }[]
  'totalTrackedTime': number
}

export type CaseFileHydraCollectionItem = Pick<
  CaseFileHydraItem,
  | 'sort'
  | 'confirmedAt'
  | '@type'
  | 'debtorName'
  | 'executor'
  | 'creditorName'
  | 'status'
  | 'collectionCostGroupName'
  | 'outstandingAmount'
  | 'paidAmount'
  | 'reservedPaidAmount'
  | 'type'
  | 'administration'
> &
HydraContext & {
  debtor: string // iri reference
  creditor: string // iri reference
  executorName?: string
  closeReasonName?: string
  createdBy: UserSnapshotHydraItem // iri reference
  // TODO: COMING BUT NOT ADDED YET
  closeReason?: string // iri reference
  closedAt?: string
}

export function transformCaseFileHydraToCollectionItem(
  item: CaseFileHydraItem,
): CaseFileHydraCollectionItem {
  return item
}

export function caseFileObjectSchema({
  t,
  getCustomFieldValues,
}: {
  t: (v: string) => string
  getCustomFieldValues: () => CustomFieldValueValueSchemaValidate[]
}) {
  return object({
    creditor: string().required().default(''),
    caseFileLists: array().of(string()).default([]),
    sort: mixed().oneOf(Object.values(CaseFileSortEnum)),
    executor: string().nullable(),
    status: string().required(),
    administration: string().required(),
    collectionCostGroup: string().nullable(),
  }).concat(customFieldValuesSchema({
    t,
    getCustomFieldValues,
  }))
}

export type CaseFileObjectSchema = InferType<
  ReturnType<typeof caseFileObjectSchema>
>

function newDebtorOptions(rawVal: any | any[], schema: Schema) {
  const val = Array.isArray(rawVal) ? rawVal[0] : rawVal
  return val === true ? schema.required() : schema.notRequired()
}

function isBusiness([newDeb, type]: any[], schema: Schema) {
  return type && type === DebtorType.Organization
    ? newDebtorOptions(newDeb, schema)
    : schema.notRequired()
}

function isIndividual([newDeb, type]: any[], schema: Schema) {
  return type && type === DebtorType.Individual
    ? newDebtorOptions(newDeb, schema)
    : schema.notRequired()
}

export function caseFileDebtorSchema({
  t,
  getCustomFieldValues,
}: {
  t: ReturnType<typeof useI18n>['t']
  getCustomFieldValues: () => CustomFieldValueValueSchemaValidate[]
}) {
  const MAX_IMAGE_SIZE = 20 * 1024 * 1024 // 25 MB in bytes

  return object({
    /* Edit debtor fields */
    creditor: string().required(),

    debtorType: number().oneOf([1, 2]).when('newDebtor', newDebtorOptions),
    name: string().when('newDebtor', newDebtorOptions),
    newDebtor: boolean().default(false),
    /* Personal debtor fields */
    salutation: string().when(['newDebtor', 'debtorType'], isIndividual),
    birthDateAt: date().nullable().default(null),
    type: string().nullable().default(null),
    /* Business debtor fields */
    tradeName: string(),
    chamberOfCommerceNumber: string(),
    valueAddedTaxNumber: string(),
    legalForm: string().when(['newDebtor', 'debtorType'], isBusiness),
    /* General debtor fields */
    picture: createFileValidator({ maxSize: MAX_IMAGE_SIZE, validExtensions: validImageExtensions, t }),
    number: string().required(),
    emailAddress: string()
      .matches(emailRegex, { message: t('common.forms.email') })
      .required(),
    phoneNumberCountryCode: string().default('NL'),
    phoneNumber: string()
      .test('validate-phone', t('common.forms.phone'), (value, context) => {
        if (value === undefined || value === '')
          return true
        return isValidPhoneNumber(value, context.parent.phoneNumberCountryCode)
      })
      .optional(),
    mobilePhoneNumberCountryCode: string().default('NL'),
    mobilePhoneNumber: string()
      .test('validate-phone', t('common.forms.phone'), (value, context) => {
        if (value === undefined || value === '')
          return true
        return isValidPhoneNumber(
          value,
          context.parent.mobilePhoneNumberCountryCode,
        )
      })
      .optional(),
    locale: string().required(),
    tenant: string().when('newDebtor', newDebtorOptions),
    /* Address related fields */
    timezone: string().required(),
    addressLine1: string().when('newDebtor', newDebtorOptions),
    locality: string().when('newDebtor', newDebtorOptions),
    country: string().when('newDebtor', newDebtorOptions),
    zipCode: string().when('newDebtor', newDebtorOptions),
    profile_additional_contact_information: profileRawSchema(t).profile_additional_contact_information,
  }).concat(customFieldValuesSchema({
    t,
    getCustomFieldValues,
  }))
}

export type CaseFileDebtorSchema = InferType<
  ReturnType<typeof caseFileDebtorSchema>
>
export type keyOfCaseFileDebtorSchema = (keyof CaseFileDebtorSchema)[]

export function caseFileReplaceDebtorSchema(t: (v: string) => string) {
  return object({
    debtor: string().required(t('common.forms.required')),
    debtorType: number()
      .oneOf([DebtorType.Organization, DebtorType.Individual])
      .required(),
  })
}

export type CaseFileReplaceDebtorSchema = InferType<
  ReturnType<typeof caseFileReplaceDebtorSchema>
>

export interface CaseFileListFlat extends HydraContext {
  id: number
  name: string
}

export interface CaseFilesStats {
  debtors: number
  openInvoices: number
  unconfirmedCases: number
  bookmarked: number
}

// export type CasefileCreate = Pick<CaseFileHydraItem, 'creditor'> & { creditor: string };
export interface CasefileEdit {
  creditor: string
}

export function caseFileSortOptions(t: (v: string) => string): {
  label: string
  value: CaseFileSortEnum
}[] {
  return [
    {
      label: t('case_file.sort.options.1'),
      value: CaseFileSortEnum.CREDIT_MANAGEMENT,
    },
    {
      label: t('case_file.sort.options.2'),
      value: CaseFileSortEnum.COLLECTION,
    },
    {
      label: t('case_file.sort.options.3'),
      value: CaseFileSortEnum.LEGAL,
    },
  ]
}

// BULk
