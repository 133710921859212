import type { Ref } from 'vue'
import type { AppCategoryEnum, AppHydraItem, AppSubscriptionHydraItem } from './application.interface.ts'
import { StorageSerializers, useStorage } from '@vueuse/core'
import { AxiosError } from 'axios'
import { api, getAll } from '../../api'
import { useServerErrorHandler } from '../../utils'
import { PermissionAttributes, usePermissions } from '../permissions'

export const useAppSubscriptions = createGlobalState(({
  category,
  debouncedSearch,
}: {
  category?: Ref<AppCategoryEnum | null>
  debouncedSearch?: Ref<string>
} = {}) => {
  const { hasPermission } = usePermissions()

  const allowReadAppSubscriptions = computed(() =>
    hasPermission(
      PermissionAttributes.GLOBAL.OPERATION.APP_SUBSCRIPTION.READ_SINGLE,
      { fuzzy: true },
    ),
  )

  const appSubscriptions = useStorage<Record<string, AppSubscriptionHydraItem>>(
    'app_subscriptions',
    {},
    undefined,
    {
      serializer: StorageSerializers.object,
    },
  )

  const hasAppSubscriptions = computed(() => Object.values(appSubscriptions.value).length > 0)

  const appSubscriptionsList = computed(() => {
    if (category?.value === null) {
      return Object.values(appSubscriptions.value).filter(sub => sub.app.name.toLowerCase().includes(debouncedSearch?.value.toLowerCase() ?? ''))
    }
    return Object.values(appSubscriptions.value).filter(sub => sub.app.category === category?.value && sub.app.name.toLowerCase().includes(debouncedSearch?.value.toLowerCase() ?? ''))
  })

  const deleteAppSubscription = (
    appSubscription: AppSubscriptionHydraItem | string,
  ) => {
    const iri = typeof appSubscription === 'string' ? appSubscription : appSubscription['@id']

    if (!iri) {
      return
    }
    if (!appSubscriptions.value[iri]) {
      return
    }
    delete appSubscriptions.value[iri]
  }
  const upsertAppSubscription = (appSubscription: AppSubscriptionHydraItem) => {
    appSubscriptions.value[appSubscription['@id']] = appSubscription
  }

  const getAppSubscriptionFromAppId = (application?: AppHydraItem | null) => {
    if (!application) {
      return null
    }
    return appSubscriptionsList.value.find(
      sub => sub.app.id === application?.id,
    )
  }

  const getAppSubscription = (iri?: string | null): null | AppSubscriptionHydraItem => {
    if (!iri) {
      return null
    }
    const app = appSubscriptions.value[iri]

    if (!app) {
      return null
    }
    return app
  }

  const getAppSubscriptions = async ({ signal }: {
    signal?: AbortSignal
  }) => {
    if (!allowReadAppSubscriptions.value) {
      appSubscriptions.value = {}
      return
    }
    const params = new URLSearchParams()

    await getAll<AppSubscriptionHydraItem>({
      url: '/api/app_subscriptions',
      initialParams: params,
      signal,
    }).then((data: AppSubscriptionHydraItem[]) => {
      appSubscriptions.value = data.reduce(
        (acc, item) => {
          acc[item['@id']] = item
          return acc
        },
        {} as Record<string, AppSubscriptionHydraItem>,
      )
      return data
    })
  }

  const { onError } = useServerErrorHandler()

  async function fetchAppSubscription(id: string | number) {
    try {
      const { data } = await api.get<AppSubscriptionHydraItem>(`/api/app_subscriptions/${id}`)
      upsertAppSubscription(data)
    }
    catch (e) {
      if (e instanceof AxiosError && e.response?.status === 404) {
        deleteAppSubscription(`/api/app_subscriptions/${id}`)
        return
      }
      console.log(e)
      onError(e)
      throw e
    }
  }

  return {
    fetchAppSubscription,
    getAppSubscription,
    allowReadAppSubscriptions,
    appSubscriptions,
    hasAppSubscriptions,
    appSubscriptionsList,
    deleteAppSubscription,
    upsertAppSubscription,
    getAppSubscriptionFromAppId,
    getAppSubscriptions,
  }
})
