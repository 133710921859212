import type { LoginResponse } from '../auth/auth.interface'
import { useAuthStore } from '../auth/useAuthStore'

export async function onAuthenticateSuccess(response: LoginResponse): Promise<void> {
  const authStore = useAuthStore()

  authStore.setToken(response.access_token)
  authStore.setRefreshToken(response.refresh_token)
  await authStore.initUser()
  await authStore.initPermissions()
}
